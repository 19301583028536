import axios from 'axios';
import { triagePrivateTypes as types } from '@/store/types';

// STATE
const cleanState = {
  triageUUID: '',
};

const defaultState = () => ({
  ...cleanState,
});

// GETTERS
const _getters = {
  [types.getters.GET_TRIAGE_UUID]: state => state.triageUUID,
};

// MUTATIONS
const mutations = {
  [types.mutations.SET_TRIAGE_UUID](state, triageUUID) {
    state.triageUUID = triageUUID;
  },
};

// ACTIONS
const actions = {
  async [types.actions.START_TRIAGE]({ commit }) {
    const { data } = await axios.post('member/v1/triage');

    commit(types.mutations.SET_TRIAGE_UUID, data.uuid);
    return data;
  },
  [types.actions.FINISH_TRIAGE]({ commit }) {
    commit(types.mutations.SET_TRIAGE_UUID, '');
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters: _getters,
  mutations,
  actions,
};
