import Vue from 'vue';
import { tools } from '@sword-health/ui-core';
import { STORAGE_KEYS } from '@/scripts/constants';
import { sendTrackingIdentity } from '@/scripts/tracking-util';
import { types as accountEndpointsTypes } from '@/http/endpoints/AccountEndpoints';
import {
  memberPrivateTypes as types,
  authStoreTypes,
} from '@/store/types';
import { env } from '@/config';

// INITIALIZATION
const memberStorage = new tools.StorageInterface(sessionStorage);

const removeUndefinedKeys = obj => Object.entries(obj).reduce((acc, [key, value]) => {
  if (value !== undefined) {
    acc[key] = value;
  }
  return acc;
}, {});

// STATE
const cleanState = {
  accountId: null,
  name: null,
  firstName: null,
  lastName: null,
  photo: null,
  language: null,
  isImpersonated: false,
  timezone: null,
  age: null,
  fingerprint: null,
  phone: null,
  isSSO: false,
};

export const defaultState = () => {
  const userFromStorage = memberStorage.read(STORAGE_KEYS.MEMBER);
  if (userFromStorage && env.VUE_APP_ANALYTICS_BACKEND) {
    sendTrackingIdentity({
      fingerprint: userFromStorage.fingerprint,
      accountID: userFromStorage.accountId,
    });
  }

  return {
    // Load the `authData` into `LOAD_STORED_AUTH_TOKENS` action (and not here),
    // because that way we can have `SET_AUTH_DATA` trigger, that is needed in http-config file
    ...cleanState,
    ...userFromStorage,
  };
};

// GETTERS
const _getters = {
  [types.getters.GET_MEMBER]: state => state,
  [types.getters.GET_ACCOUNT_ID]: state => state.accountId,
  [types.getters.GET_FINGERPRINT]: state => state.fingerprint,
  [types.getters.GET_MEMBER_LANGUAGE]: state => state.language,
  [types.getters.IS_IMPERSONATED]: state => state.isImpersonated,
};

// MUTATIONS
const mutations = {
  [types.mutations.SET_ACCOUNT_ID]: (state, accountId) => {
    state.accountId = accountId;
  },

  [types.mutations.SET_MEMBER]: (state, member) => {
    const {
      accountId, name, firstName, lastName,
      photo, language, isImpersonated, timezone, age, fingerprint, phone,
    } = member;
    state.accountId = accountId;
    state.name = name;
    state.phone = phone;
    state.firstName = firstName;
    state.lastName = lastName;
    state.photo = photo;
    state.language = language;
    state.isImpersonated = isImpersonated;
    state.timezone = timezone;
    state.age = age;
    state.fingerprint = fingerprint;
  },

  [types.mutations.UPDATE_MEMBER]: (state, member) => {
    const {
      accountId, name, photo, language, age,
    } = member;

    if (accountId !== undefined) {
      state.accountId = accountId;
    }
    if (name !== undefined) {
      state.name = name;
    }
    if (photo !== undefined) {
      state.photo = photo;
    }
    if (language !== undefined) {
      state.language = language;
    }
    if (age !== undefined) {
      state.age = age;
    }
  },

  [types.mutations.SET_MEMBER_IS_SSO]: (state, isSSO) => {
    state.isSSO = isSSO;
  },
};

// ACTIONS
const actions = {
  [types.actions.RESET_MEMBER]: ({ commit }) => {
    memberStorage.delete(STORAGE_KEYS.MEMBER);
    commit(types.mutations.SET_MEMBER, cleanState);
  },

  [types.actions.GET_MEMBER]: async ({ commit, dispatch }) => {
    try {
      const { data: memberData } = await Vue.$http(accountEndpointsTypes.GET_MEMBER);
      const member = {
        accountId: memberData.account_id,
        name: `${memberData.first_name} ${memberData.last_name}`,
        firstName: memberData.first_name,
        lastName: memberData.last_name,
        photo: memberData.photo,
        language: memberData.preferred_language,
        isImpersonated: memberData.is_impersonated,
        timezone: memberData.timezone,
        age: memberData.age,
        fingerprint: memberData.fingerprint,
        phone: memberData.phone,
      };

      memberStorage.write(STORAGE_KEYS.MEMBER, member);
      commit(types.mutations.SET_MEMBER, member);
      if (env.VUE_APP_ANALYTICS_BACKEND) {
        sendTrackingIdentity({
          fingerprint: memberData.fingerprint,
          accountID: memberData.account_id,
        });
      }
    } catch (error) {
      console.log('Failed to get member', error);
      dispatch(types.actions.RESET_MEMBER);
      throw error;
    }
  },

  [types.actions.UPDATE_MEMBER]: async ({ commit, rootGetters }, updatedMember) => {
    if (!rootGetters[authStoreTypes.getters.IS_LOGGED_IN] || !updatedMember) {
      return;
    }

    const {
      language, firstName, lastName, phone,
    } = updatedMember;
    const updatedFields = removeUndefinedKeys({
      preferred_language: language,
      first_name: firstName,
      last_name: lastName,
      phone,
    });

    if (!updatedFields || !Object.keys(updatedFields).length) {
      return;
    }

    await Vue.$http(accountEndpointsTypes.UPDATE_MEMBER, null, {
      body: updatedFields,
    });
    commit(types.mutations.UPDATE_MEMBER, updatedMember);
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters: _getters,
  mutations,
  actions,
};
