// eslint-disable-next-line import/prefer-default-export
export const CONFIGURATIONS = {
  DPT: 'dpt',
  DPT_GO: 'dpt_go',
  BLOOM: 'bloom',
  MOVE: 'move',
  MIND: 'mind',
  SUPPORT_EMAIL: 'support_email',
  SUPPORT_PHONE_NUMBER: 'support_phone_number',
  COUNTRY: 'country',
  LANGUAGE: 'language',
  AVAILABLE_LANGUAGES: 'available_languages',
  VIDEO_CALL: 'video_call',
  SCHEDULE: {
    MIN_DISTANCE_HOURS: 'minimum_scheduling_distance_hours',
    NUMBER_OF_DAYS: 'number_of_days_to_schedule',
  },
  CONDITIONS: 'conditions',
  LEGAL_LINKS: 'legal_links',
  NAVIGATOR_MODE: 'navigator_mode',
  CUSTOM_CLIENT_TITLE: 'custom_client_title',
  CUSTOM_NAVIGATOR_TITLES: 'custom_navigator_titles',
  NAVIGATOR_HIDE_NON_ELIGIBLE_UNITS: 'navigator_hide_non_eligible_units',
  ENROLLMENT_REFERRALS: 'enrollment_referrals',
  DATE_FORMAT: 'date_format',
};
