import Vue from 'vue';

function generateMemberInviteApiBody(invitationForm, referralRequired, legal) {
  const { careCoordinator: coordinator, member, carePathway } = invitationForm;

  const generatedInviteBody = {
    care_coordinator: {
      first_name: coordinator.firstName,
      last_name: coordinator.lastName,
      email: coordinator.email,
      phone_number: coordinator.phone,
      member_relationship: coordinator.relationshipWithMember,
    },
    member: {
      first_name: member.firstName,
      last_name: member.lastName,
      email: member.email,
      date_of_birth: member.birthdate,
      geographic_state: member.geographicalState,
    },
    care_pathways: {
      care_pathway_option: carePathway.carePathwayOption,
      surgery_date: carePathway.surgeryDate,
      surgical_notes: carePathway.surgicalNotes,
    },
    legal: {
      terms_of_use_url: legal.invitationTermsOfUse,
      privacy_notice_url: legal.invitationNoticeOfPrivacy,
      non_discrimination_url: legal.invitationNonDiscrimination,
    },
    client_reference: member.client,
    service_type: member.serviceType,
    page_uuid: invitationForm.inviteUUID,
  };

  if (!referralRequired) {
    return generatedInviteBody;
  }
  const formPayload = new FormData();
  formPayload.append('referral_file', member.referralFile);
  formPayload.append('invite_data', JSON.stringify(generatedInviteBody));

  return formPayload;
}

const DEFAULT_REQUIRED_VAL = serviceType => serviceType === 'pt_provider';
function isClinicalReferralRequired(clinicalReferralRules, memberGeographicalState, serviceType, bypassToggle) {
  if (bypassToggle || serviceType === 'wellness') {
    return false;
  }

  const DEFAULT_OUTPUT = DEFAULT_REQUIRED_VAL(serviceType);
  if (!memberGeographicalState.length || !serviceType || typeof clinicalReferralRules !== 'object') {
    return DEFAULT_OUTPUT;
  }

  function checkIsRequiredForServiceType(geoStateObj, needleServiceType) {
    const providedType = geoStateObj.find(item => item.key === needleServiceType);
    if (providedType) {
      return typeof providedType.value === 'boolean' ? providedType.value : DEFAULT_OUTPUT;
    }
    return DEFAULT_OUTPUT;
  }

  for (let i = 0; i < Object.keys(clinicalReferralRules).length; i = 1 + i) {
    const geoState = clinicalReferralRules[i];
    for (let j = 0; j < Object.keys(geoState.value).length; j = 1 + j) {
      const config = geoState.value[j];
      if (config.key === 'code' && memberGeographicalState === config.value) {
        return checkIsRequiredForServiceType(geoState.value, serviceType);
      }
    }
  }

  return DEFAULT_OUTPUT;
}

const defaultInvitationState = () => ({
  clinicalReferralRules: {},
  targetClients: [],
  inviteUID: '',
  careCoordinator: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '+1//',
    relationshipWithMember: '',
  },
  member: {
    firstName: '',
    lastName: '',
    birthdate: '',
    email: '',
    geographicalState: '',
    client: '',
    targetClient: '',
    serviceType: '',
    referralFile: '',
  },
  carePathway: {
    carePathwayOption: '',
    surgeryDate: '',
    surgicalNotes: '',
  },
});

export default {
  namespaced: true,
  state: defaultInvitationState(),
  getters: {
    getInviteUID: state => state.inviteUID,
    getTargetClients: state => state.targetClients,
    getCareCoordinatorData: state => prop => prop ? state.careCoordinator[prop] : state.careCoordinator,
    getMemberData: state => prop => prop ? state.member[prop] : state.member,
    getCarePathwayData: state => prop => prop ? state.carePathway[prop] : state.carePathway,
    requireReferral: (state, getters) => {
      const selectedTargetClientReference = getters.getMemberData('client');
      const bypassToggle = getters.getTargetClients.some(targetClientOpt => {
        if (!targetClientOpt.configuration || !targetClientOpt.configuration.toggles) {
          return false;
        }

        return targetClientOpt.reference === selectedTargetClientReference && targetClientOpt.configuration.toggles.bypass_clinical_referral_rules;
      });

      return isClinicalReferralRequired(
        state.clinicalReferralRules,
        getters.getMemberData('geographicalState'),
        getters.getMemberData('serviceType'),
        bypassToggle,
      );
    },
  },
  mutations: {
    setCareCoordinatorData(state, payload) {
      Object.keys(payload).forEach(prop => {
        state.careCoordinator[prop] = payload[prop];
      });
    },
    setMemberData(state, payload) {
      Object.keys(payload).forEach(prop => {
        state.member[prop] = payload[prop];
      });
    },
    setCarePathwayData(state, payload) {
      Object.keys(payload).forEach(prop => {
        state.carePathway[prop] = payload[prop];
      });
    },
    setInviteUID(state, uid) {
      state.inviteUID = uid;
    },
    setTargetClients(state, clients) {
      state.targetClients = clients;
    },
    setClinicalReferralConfigs(state, rules) {
      state.clinicalReferralRules = rules;
    },
  },
  actions: {
    async getClinicalReferralConfigs({ rootGetters, commit }) {
      const { reference: clientReference } = rootGetters['client/getClientIdentifiers'];

      const { data } = await Vue.$http('invitation/getClinicalReferralRules', { clientReference });

      commit('setClinicalReferralConfigs', data);
    },
    async submitInvite({ getters, rootGetters }, formData) {
      const legal = rootGetters['client/getDefaultLegalLinks'];
      const body = generateMemberInviteApiBody(formData, getters.getMemberData('referralFile'), legal);
      const config = {
        headers: {
          'Content-Type': getters.getMemberData('referralFile') ? 'multipart/form-data' : 'application/json',
        },
      };
      return Vue.$http('invitation/submitInvitation', null, { body, config });
    },
    async getInviteData({ rootGetters, commit }, invitationUUID) {
      const { reference: clientReference } = rootGetters['client/getClientIdentifiers'];

      const { data } = await Vue.$http('invitation/fetchInvitation', { clientReference, invitationUUID });

      if (data.deleted_at !== null) {
        throw Error({ code: 'invalid_invite', description: `This invite has been deleted at ${data.deleted_at}` });
      }
      const inviteClientReference = data.client && data.client.reference;

      if (inviteClientReference !== clientReference) {
        throw Error({
          code: 'invalid_invite',
          description: `Invite reference and client reference missmatch.
            Invite Reference: ${inviteClientReference}, URL Reference: ${clientReference}`,
        });
      }

      const { page_uuid: pageUUID } = data;
      commit('setInviteUID', pageUUID);
      return data;
    },
    async fetchTargetClients({ commit }, targets) {
      const clientReferences = targets.map(c => c.target.split('|')[1]);

      const appendTargetInfoToClient = client => {
        const targetIndex = targets.findIndex(c => c.target.split('|')[1] === client.reference);
        const { target } = targets[targetIndex];
        const serviceType = target.split('|')[2];

        // eslint-disable-next-line no-param-reassign
        client.serviceType = serviceType;
        // eslint-disable-next-line no-param-reassign
        client.target = target;
        targets.splice(targetIndex, 1);
        return client;
      };

      try {
        const fetchBatch = clientReferences.map(ref => Vue.$http('client/fetchClient', ref));
        const requests = await Promise.all(fetchBatch);
        const clients = requests.map(r => appendTargetInfoToClient(r.data));
        commit('setTargetClients', clients);
        return clients;
      } catch (error) {
        console.warn('failed to fetch target clients', error);
        return [];
      }
    },
    resetFormData({ commit }) {
      const defaultMemberData = defaultInvitationState().member;
      const defaultCarePathway = defaultInvitationState().carePathway;

      commit('setMemberData', defaultMemberData);
      commit('setCarePathwayData', defaultCarePathway);
    },
  },
};
