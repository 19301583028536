import { env } from '@/config';
import { UNITS } from '@/scripts/constants/units';

export const BASE_URLS = {
  BASE: env.VUE_APP_API_BASE_URL,
  NON_DISCRIMINATION: env.VUE_APP_NON_DISCRIMINATION_URL,
  MINOR_ELIGIBILITY_CONSENT_URL: env.VUE_APP_MINOR_ELIGIBILITY_CONSENT_URL,
  INVITATION_TERMS_OF_USE: env.VUE_APP_INVITATION_TERMS_OF_USE_URL,
  INVITATION_PRIVACY_NOTICE: env.VUE_APP_INVITATION_NOTICE_OF_PRIVACY_URL,
  INVITATION_NON_DISCRIMINATION: env.VUE_APP_INVITATION_NON_DISCRIMINATION_URL,
  WHEEL_CONSENT_URL: env.VUE_APP_WHEEL_CONSENT_URL,
  WHEEL_PRIVACY_URL: env.VUE_APP_WHEEL_PRIVACY_URL,
  WHEEL_INFORMED_CONSENT_URL: env.VUE_APP_WHEEL_INFORMED_CONSENT_URL,
  OPENLOOP_PRIVACY_POLICY_URL: env.VUE_APP_OPENLOOP_PRIVACY_POLICY_URL,
  OPENLOOP_TERMS_AND_CONDITIONS_URL: env.VUE_APP_OPENLOOP_TERMS_AND_CONDITIONS_URL,
  OPENLOOP_TELEHEALTH_URL: env.VUE_APP_OPENLOOP_TELEHEALTH_URL,
  HEALTH_GORILLA_CONSENT_URL: env.VUE_APP_HEALTH_GORILLA_CONSENT_URL,
  IRB_CONSENT_URL: env.VUE_APP_IRB_CONSENT_URL,
  GDPR_CONSENT_URL: env.VUE_APP_GDPR_CONSENT_URL,
  JOIN_SWORD: env.VUE_APP_JOIN_SWORD_URL,
  API_MEMBER: env.VUE_APP_API_MEMBER_URL,
  PATIENTAPP_GOOGLE_PLAY_URL: env.VUE_APP_PATIENTAPP_GOOGLE_PLAY_URL,
  PATIENTAPP_APPLE_STORE_URL: env.VUE_APP_PATIENTAPP_APPLE_STORE_URL,
  PATIENTAPP_MOVE_ENROLLMENT_DEEPLINK: env.VUE_APP_PATIENTAPP_MOVE_ENROLLMENT_DEEPLINK,
  VIDEO_CALL_SUMMARY_VIDEO_ENABLED: env.VUE_APP_VIDEO_CALL_SUMMARY_VIDEO_ENABLED,
  SUMMARY_VIDEO_LINK: env.VUE_APP_SUMMARY_VIDEO_LINK,
  VIDEO_CALL_SUMMARY_VIDEO_LINK: env.VUE_APP_VIDEO_CALL_SUMMARY_VIDEO_LINK,
  OPTICAL_SUMMARY_VIDEO_LINK: env.VUE_APP_OPTICAL_SUMMARY_VIDEO_LINK,
  OPTICAL_VIDEO_CALL_SUMMARY_VIDEO_LINK: env.VUE_APP_OPTICAL_VIDEO_CALL_SUMMARY_VIDEO_LINK,
  TERMS_AND_CONDITIONS_URL: env.VUE_APP_TERMS_AND_CONDITIONS_URL,
  CONSENT_TO_TELEHEALTH_SERVICES_URL: env.VUE_APP_CONSENT_TO_TELEHEALTH_SERVICES_URL,
  PRIVACY_STATEMENT_URL: env.VUE_APP_PRIVACY_STATEMENT_URL,
  HIPAA_NOTICE_OF_PRIVACY_PRACTICES_URL: env.VUE_APP_HIPAA_NOTICE_OF_PRIVACY_PRACTICES_URL,
  PT_GDPR_CONSENT_URL: env.VUE_APP_PT_GDPR_CONSENT_URL,
  PT_PRIVACY_STATEMENT_URL: env.VUE_APP_PT_PRIVACY_STATEMENT_URL,
  ES_NON_DISCRIMINATION: env.VUE_APP_ES_NON_DISCRIMINATION_URL,
  ES_MINOR_ELIGIBILITY_CONSENT_URL: env.VUE_APP_ES_MINOR_ELIGIBILITY_CONSENT_URL,
  ES_INVITATION_TERMS_OF_USE: env.VUE_APP_ES_INVITATION_TERMS_OF_USE_URL,
  ES_INVITATION_PRIVACY_NOTICE: env.VUE_APP_ES_INVITATION_NOTICE_OF_PRIVACY_URL,
  ES_INVITATION_NON_DISCRIMINATION: env.VUE_APP_ES_INVITATION_NON_DISCRIMINATION_URL,
  ES_HEALTH_GORILLA_CONSENT_URL: env.VUE_APP_ES_HEALTH_GORILLA_CONSENT_URL,
  ES_IRB_CONSENT_URL: env.VUE_APP_ES_IRB_CONSENT_URL,
  ES_HIPAA_NOTICE_OF_PRIVACY_PRACTICES_URL: env.VUE_APP_ES_HIPAA_NOTICE_OF_PRIVACY_PRACTICES_URL,
  ES_TERMS_AND_CONDITIONS_URL: env.VUE_APP_ES_TERMS_AND_CONDITIONS_URL,
  ES_CONSENT_TO_TELEHEALTH_SERVICES_URL: env.VUE_APP_ES_CONSENT_TO_TELEHEALTH_SERVICES_URL,
  ES_PRIVACY_STATEMENT_URL: env.VUE_APP_ES_PRIVACY_STATEMENT_URL,
  FR_CA_IRB_CONSENT_URL: env.VUE_APP_FR_CA_IRB_CONSENT_URL,
  FR_CA_CONSENT_TO_TELEHEALTH_SERVICES_URL: env.VUE_APP_FR_CA_CONSENT_TO_TELEHEALTH_SERVICES_URL,
  FR_CA_HEALTH_GORILLA_CONSENT_URL: env.VUE_APP_FR_CA_HEALTH_GORILLA_CONSENT_URL,
  FR_CA_INVITATION_NON_DISCRIMINATION: env.VUE_APP_FR_CA_INVITATION_NON_DISCRIMINATION_URL,
  FR_CA_INVITATION_PRIVACY_NOTICE: env.VUE_APP_FR_CA_INVITATION_NOTICE_OF_PRIVACY_URL,
  FR_CA_INVITATION_TERMS_OF_USE: env.VUE_APP_FR_CA_INVITATION_TERMS_OF_USE_URL,
  FR_CA_NON_DISCRIMINATION: env.VUE_APP_FR_CA_NON_DISCRIMINATION_URL,
  FR_CA_PRIVACY_STATEMENT_URL: env.VUE_APP_FR_CA_PRIVACY_STATEMENT_URL,
  FR_CA_TERMS_AND_CONDITIONS_URL: env.VUE_APP_FR_CA_TERMS_AND_CONDITIONS_URL,
};

export const URLS_BY_UNIT = {
  [UNITS.BLOOM]: {
    ...BASE_URLS,
    IRB_CONSENT_URL: env.VUE_APP_UNIT_BLOOM_IRB_CONSENT_URL,
  },
};

export const CONFIGS = {
  VUE_APP_SHOW_GDPR_CONSENT: env.VUE_APP_SHOW_GDPR_CONSENT,
  VUE_APP_SHOW_NON_DISCRIMINATION_CONSENT: env.VUE_APP_SHOW_NON_DISCRIMINATION_CONSENT,
  VUE_APP_SHOW_HEALTH_GORILLA_CONSENT: env.VUE_APP_SHOW_HEALTH_GORILLA_CONSENT,
  VUE_APP_SHOW_IRB_CONSENT: env.VUE_APP_SHOW_IRB_CONSENT,
  VUE_APP_SHOW_HIPAA_CONSENT: env.VUE_APP_SHOW_HIPAA_CONSENT,
};

export const STORAGE_KEYS = {
  CLIENT: 'ONBOARDING_CLIENT',
  ACCESS_TOKEN: 'access_token',
  REFRESH_TOKEN: 'refresh_token',
  LOCKED_LOGIN: 'ONBOARDING_LOCKED_LOGIN',
  MEMBER: 'ONBOARDING_MEMBER',
  PRESELECTED_PROGRAM: 'ONBOARDING_PRESELECTED_PROGRAM',
  SELECTED_LANGUAGE: 'ONBOARDING_LANGUAGE',
  ENROLLED_LIST: 'ENROLLED_LIST',
  MULTIMODE_INVITE_ID: 'MULTIMODE_INVITE_ID',
  PROGRAM_TYPE: 'PROGRAM_TYPE',
};

export const COOKIES_KEYS = {
  USER_SESSION: 'session',
  MEMBER_UUID_PROP: 'UUIDMap', // this needs to be syncd with Gateway
  DEFAULT_CLIENT: '_noclient', // this needs to be syncd with Gateway
  REFERRAL_TOKEN: 'referral_token',
  HIDE_GET_STARTED_PAGE: 'hide_get_started_page',
};

export const AUTH_ERROR_REASONS = {
  ACCOUNT_ALREADY_EXISTS: 'ACCOUNT_ALREADY_EXISTS',
  ACCOUNT_BLOCKED: 'ACCOUNT_BLOCKED',
  ACCOUNT_SUSPENDED: 'ACCOUNT_SUSPENDED',
  PASSWORD_BROKEN: 'PASSWORD_BROKEN',
  BAD_CREDENTIALS: 'BAD_CREDENTIALS',
  INVALID_REQUEST: 'INVALID_REQUEST',
  TOKEN_EXPIRED: 'TOKEN_EXPIRED',
};

export const SESSION_TIMEOUT_CONFIG = {
  SESSION_TIMEOUT: env.VUE_APP_SESSION_LOGOUT_TIMEOUT, // timeout in seconds
  SESSION_DEFAULT_TIMEOUT: 300, // 5 minutes
  SESSION_TIMER_COOKIE_NAME: 'session_timer_start',
};

export const DEFAULT_CLIENT_INFO = {
  PHONE_NUMBER: '888-492-1860',
};

export const BLOOM = {
  EMAIL_SUFFIX: '+bg9s@',
};
