import * as units from './units';

let tmpEnv = {
  ...window.CONFIG ?? {},
};

if (process.env.NODE_ENV === 'development') {
  try {
    // eslint-disable-next-line
    const defaults = require('./defaults.json');
    let local;
    try {
    // eslint-disable-next-line
    local = require('./local.json')
    } catch {
      console.error('local.json does not exist probably');
    }

    if (defaults || local) {
      tmpEnv = {
        ...tmpEnv,
        ...defaults,
        ...local,
      };
    }
  } catch (error) {
    console.error(error);
  }
}

export const env = tmpEnv;

const unitName = env.VUE_APP_UNIT || 'sword';
const unit = units[unitName];

const config = Object.freeze({
  appName: 'ui-onboarding',

  unit,
  unitName,
  isBloom: unitName === 'bloom',
});

export default config;
