
// TYPES
export const CLIENT_ENDPOINT_NAMESPACE = 'client';
const getTypes = hasNamespace => {
  const prefix = hasNamespace ? `${CLIENT_ENDPOINT_NAMESPACE}/` : '';
  return {
    FETCH_CLIENT: `${prefix}fetchClient`,
    FETCH_PAYERS: `${prefix}fetchPayers`,
    FETCH_CLIENT_SSO: `${prefix}fetchClientSSO`,
  };
};

export const clientEndpointsTypes = getTypes(true);
export const privateTypes = getTypes(false);

export default {
  /**
   * @memberof Endpoints/OnboardingAPI
  * @name onboarding/v1/reference?c
  * @type GET
   * @summary Get information of a client using the client's reference
   * @property c {QueryParam} Query param with clients reference
   */
  [privateTypes.FETCH_CLIENT]: {
    api: {
      url: 'default',
      version: '1',
    },
    $$method: 'get',
    $$config: {},
    $$makeUrl(configs, clientRef) {
      return `onboarding/v${configs.api.version}/reference?c=${clientRef}`;
    },
  },
  /**
   * @memberof Endpoints/EligibilityAPI
   * @name eligibility/v1/configs/_clientId_/payers
   * @type GET
   * @summary Get list of payers associated with this client
   * @property clientId {Param} Id of the SWORD client
   */
  [privateTypes.FETCH_PAYERS]: {
    api: {
      url: 'default',
      version: '1',
    },
    $$method: 'get',
    $$config: {},
    $$makeUrl(configs, clientId) {
      return `eligibility/v${configs.api.version}/configs/${clientId}/payers`;
    },
  },
  /**
   * @memberof Endpoints/Client
   * @name /v1/client/authentications
   * @type GET
   * @summary Get list of sso associated with this client
   * @property clientRef {Param} of the SWORD client
   */
  [privateTypes.FETCH_CLIENT_SSO]: {
    api: {
      url: 'default',
      version: '1',
    },
    $$method: 'get',
    $$config: {},
    $$makeUrl(configs, clientRef) {
      return `member/v${configs.api.version}/client/authentications?client_ref=${encodeURIComponent(clientRef)}`;
    },
  },
};
