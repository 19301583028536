import { tools } from '@sword-health/ui-core';
import { STORAGE_KEYS } from '@/scripts/constants';

// INITIALIZATION
const invitationSessionStorage = new tools.StorageInterface(sessionStorage);

export function getMultimodeInviteId() {
  return invitationSessionStorage.read(STORAGE_KEYS.MULTIMODE_INVITE_ID);
}

export function saveMultimodeInviteId(multimodeInviteId) {
  if (multimodeInviteId) {
    invitationSessionStorage.write(STORAGE_KEYS.MULTIMODE_INVITE_ID, multimodeInviteId);
  }
}

export function deleteMultimodeInviteId() {
  invitationSessionStorage.delete(STORAGE_KEYS.MULTIMODE_INVITE_ID);
}
