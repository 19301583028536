/**
 * Setting up translations configs.
 * Language imports and bootstrap config are done here
 * @module LanguageConfigs
 */
import { language as languagesTools } from '@sword-health/ui-core';
import { env } from '@/config';
import { DEFAULT_LANGUAGE } from '@/scripts/constants/languages';
import checkIfIsBecomeDomain from '@/scripts/checkIfIsBecomeDomain';

const preferredLanguage = DEFAULT_LANGUAGE;
const languagesFolderContext = require.context('@/languages', true, /\.json$/);
const domainDirectory = checkIfIsBecomeDomain() ? 'become' : 'onboarding';

const options = {
  extractValidations: true,
  directories: [domainDirectory, 'clinical-forms'],
};

const languagesConfigs = languagesTools.swordVueLanguages(languagesFolderContext, preferredLanguage, env.LANGUAGE_FALLBACK, options);

export { languagesConfigs, preferredLanguage };
