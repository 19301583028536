/* eslint-disable no-undef */
import Vue from 'vue';
import config, { env } from '../config';

const defaultOptions = {
  unitName: 'sword',
  position: { horizontal: 'right', vertical: 'bottom' },
  offset: {
    horizontal: '8px',
    vertical: '8px',
  },
  color: {
    header: '#365D95',
    button: '#365D95',
    launcher: '#365D95',
  },
  hideOnload: true,
  hideOnClose: true,
  verbose: false,
  departments: {
    enabled: [],
  },
  onload: () => null,
  enabled: env.VUE_APP_ZENDESK_ENABLED === 'true',
};

const zendeskConfigs = {
  sword: {
    keyName: 'VUE_APP_ZENDESK_DPT',
    department: {
      normal: 'Sword Support Team 1',
      strict: 'Sword Support Team 2',
    },
  },
  bloom: {
    keyName: 'VUE_APP_ZENDESK_BLOOM',
    department: {
      normal: 'Bloom Support team',
      strict: 'Bloom Strict Support Team',
    },
  },
  move: {
    keyName: 'VUE_APP_ZENDESK_MOVE',
    department: {
      normal: 'Move Support Team',
      strict: 'Move Support Strict',
    },
  },
  dpt_go: {
    keyName: 'VUE_APP_ZENDESK_DPT',
    department: {
      normal: 'Sword Support Team 1',
      strict: 'Sword Support Team 2',
    },
  },
  mind: {
    keyName: 'VUE_APP_ZENDESK_DPT',
    department: {
      normal: 'Sword Support Team 1',
      strict: 'Sword Support Team 2',
    },
  },
};

class Zendesk {
  zendeskId = 'ze-snippet'

  options = defaultOptions

  isOpening = false;

  strict = false;

  unitName = 'sword';

  constructor(options) {
    this.unitName = options.unitName;
    this.options = { ...defaultOptions, ...options };
    if (this.options.enabled) {
      this.injectZendesk();
    } else {
      this.log('Zendesk is disabled', 'warn');
    }
  }

  reload(options, force = false) {
    if (!this.options.enabled) return;
    if (this.unitName === options.unitName && !force) return;

    this.unitName = options.unitName;
    this.options = { ...defaultOptions, ...options };
    this.injectZendesk();
  }

  show() {
    if (!this.options.enabled) return;
    if (this.isHealthy()) window.zE('webWidget', 'show');
    else this.alertUnhealthy();
  }

  hide() {
    if (!this.options.enabled) return;
    if (this.isHealthy()) window.zE('webWidget', 'hide');
    else this.alertUnhealthy();
  }

  async open(hideOnClose) {
    if (!this.options.enabled) return;
    if (this.isOpening) return;

    try {
      this.isOpening = true;
      hideOnClose = hideOnClose !== undefined ? hideOnClose : this.hideOnClose;
      await this.waitingIsHealthy();
      window.zE.activate({ hideOnClose });
    } catch {
      this.alertUnhealthy();
    } finally {
      this.isOpening = false;
    }
  }

  isHealthy() {
    return this.options.enabled && window.zE && window.zE.activate;
  }

  injectZendesk() {
    if (!this.options.enabled) return;
    this.removeZendeskIfExist();
    this.log('inject script');
    const widgetScript = this.createZendeskScriptNode(this.unitName);

    widgetScript.onload = () => {
      const {
        position, offset, hideOnload, onload, color,
      } = this.options;

      const department = this.getDepartmentByUnit(this.unitName);

      window.zESettings = {
        webWidget: {
          position,
          offset,
          color,
        },
        chat: {
          departments: {
            enabled: [department],
            select: department,
          },
        },
      };

      zE(() => {
        if (onload) onload();
        if (hideOnload) this.hide();
        this.log('web widget loaded');
      });
    };

    document.body.appendChild(widgetScript);
  }

  removeZendeskIfExist() {
    if (!this.options.enabled) return;
    const currentWidget = document.getElementById(this.zendeskId);

    if (currentWidget) {
      this.log('clean actual Zendesk widget');
      window.zE = null;
      const iframes = document.querySelectorAll('[data-product="web_widget"]');
      const launchers = document.querySelectorAll('[data-product="web_widget"]+div>#launcher');

      // remove the iframes with load web widget
      iframes.forEach(iFr => {
        this.log('remove widget');
        iFr.parentNode?.removeChild(iFr);
      });

      // remove the div parent of iframe with launcher
      launchers.forEach(iFr => {
        this.log('remove launcher');
        iFr.parentNode?.parentNode?.removeChild(iFr.parentNode);
      });

      this.log('clean global variables');
      if ('zE' in window) window.zE = null;
      if ('zEmbed' in window) window.zEmbed = null;
      if ('zEACLoaded' in window) window.zEACLoaded = false;
      if ('zESettings' in window) window.zESettings = null;
      if ('zEWebpackACJsonp' in window) window.zEWebpackACJsonp = null;

      currentWidget.parentNode?.removeChild(currentWidget);
    }
  }

  createZendeskScriptNode(unitName) {
    if (!this.options.enabled) return null;
    const widgetKey = env[zendeskConfigs[unitName].keyName];
    const zendeskScript = document.createElement('script');

    zendeskScript.id = this.zendeskId;
    zendeskScript.src = `https://static.zdassets.com/ekr/snippet.js?key=${widgetKey}`;

    return zendeskScript;
  }

  log(logMessage, type = 'log') {
    if (type === 'log' && !this.options.verbose) return;

    console[type](`[Zendesk] ${logMessage}`);
  }

  alertUnhealthy() {
    if (!this.options.enabled) {
      this.log('is disabled, please check the options.', 'error');
    } else {
      this.log('isn\'t correctly installed, please check the options.', 'error');
    }
  }

  async waitingIsHealthy() {
    return new Promise((resolve, reject) => {
      if (!this.options.enabled) {
        reject();
        return;
      }

      let callCount = 0;
      if (this.isHealthy()) {
        resolve();
        return;
      }

      const interValId = setInterval(() => {
        callCount += 1;
        if (callCount >= 10) {
          clearInterval();
          reject();
          return;
        }

        if (this.isHealthy()) {
          clearInterval(interValId);
          resolve();
        }
      }, 500);
    });
  }

  setZendeskStrictMode(isStrict) {
    if (!this.options.enabled) return;
    this.strict = isStrict;
    this.updateDepartmentSettings(this.getDepartmentByUnit(this.unitName));
  }

  getDepartmentByUnit(unitName) {
    if (!this.options.enabled) return null;
    const unitOptions = zendeskConfigs[unitName];
    return unitOptions.department[this.strict ? 'strict' : 'normal'];
  }

  updateDepartmentSettings(department) {
    if (!this.options.enabled) return;
    if (window.zE) {
      const webWidget = {
        chat: {
          departments: {
            enabled: [department],
            select: department,
          },
        },
      };

      window.zE('webWidget', 'updateSettings', {
        webWidget,
      });

      try {
        window.zESettings = {
          ...window.zESettings || {},
          ...webWidget,
        };
      } catch (error) {
        console.log(error);
      }
    }
  }
}

function setZopimConfig() {
  // Zopim API
  $zopim(() => {
    $zopim.livechat.concierge.setAvatar(`/v3/dist/${config.unitName}/favicon-32x32.png`);
    $zopim.livechat.theme.reload();
  });
  // End Zopim API
}

const zendesk = new Zendesk({
  unitName: config.unitName,
  onload: setZopimConfig,
});

export function installZendeskForUnit(unitName) {
  zendesk.reload({
    unitName,
    onload: setZopimConfig,
  });
}

Vue.prototype.$zendesk = zendesk;

export default zendesk;
