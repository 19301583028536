import { BASE_URLS } from '@/scripts/constants';

// TYPES
export const AUTH_ENDPOINT_NAMESPACE = 'auth';
const getTypes = hasNamespace => {
  const prefix = hasNamespace ? `${AUTH_ENDPOINT_NAMESPACE}/` : '';
  return {
    LOGIN: `${prefix}login`,
    REFRESH_TOKENS: `${prefix}refreshTokens`,
    RECOVER_PASSWORD: `${prefix}recoverPassword`,
    REQUEST_ACCOUNT_DELETE: `${prefix}requestAccountDelete`,
    CONFIRM_ACCOUNT_DELETE: `${prefix}confirmAccountDelete`,
    RESET_PASSWORD: `${prefix}resetPassword`,
    GET_AUTH_TYPES: `${prefix}getAuthTypes`,
  };
};

export const authEndpointsTypes = getTypes(true);
export const privateTypes = getTypes(false);

// COMMON API
const api = {
  url: BASE_URLS.API_MEMBER,
  version: '1',
};
const getApiPath = configs => `${configs.api.url}/v${configs.api.version}`;

export default {
  /**
   * @memberof Endpoints/MemberAPI
   * @name member/v1/login
   * @type POST
   * @summary Login using the users email and password.
   */
  [privateTypes.LOGIN]: {
    api,
    $$method: 'post',
    $$config: {
      headers: data => data?.headers?.refreshAuthorization,
    },
    $$makeUrl(configs) {
      return `${getApiPath(configs)}/login`;
    },
  },

  /**
   * @memberof Endpoints/MemberAPI
   * @name member/v1/refresh
   * @type GET
   * @summary Refresh users access token using the refresh token.
   */
  [privateTypes.REFRESH_TOKENS]: {
    api,
    $$method: 'get',
    $$config: {
      headers: data => data?.headers?.refreshAuthorization,
    },
    $$makeUrl(configs) {
      return `${getApiPath(configs)}/refresh`;
    },
  },

  /**
   * @memberof Endpoints/MemberAPI
   * @name member/v1/password-recovery
   * @type POST
   * @summary Request a password recovery using the users email.
   */
  [privateTypes.RECOVER_PASSWORD]: {
    api,
    $$method: 'post',
    $$makeUrl(configs) {
      return `${getApiPath(configs)}/password-recovery`;
    },
  },

  /**
   * @memberof Endpoints/MemberAPI
   * @name member/v1/delete
   * @type POST
   * @summary Request an account deletion using the access token.
   */
  [privateTypes.REQUEST_ACCOUNT_DELETE]: {
    api,
    $$method: 'post',
    $$makeUrl(configs) {
      return `${getApiPath(configs)}/delete`;
    },
  },

  /**
   * @memberof Endpoints/MemberAPI
   * @name member/v1/delete
   * @type DELETE
   * @summary Confirm the account deletion.
   */
  [privateTypes.CONFIRM_ACCOUNT_DELETE]: {
    api,
    $$method: 'delete',
    $$makeUrl(configs) {
      return `${getApiPath(configs)}/delete`;
    },
  },

  /**
   * @memberof Endpoints/MemberAPI
   * @name member/v1/password-recovery/recover
   * @type POST
   * @summary Submit a new password for an existing account.
   */
  [privateTypes.RESET_PASSWORD]: {
    api,
    $$method: 'post',
    $$makeUrl(configs) {
      return `${getApiPath(configs)}/password-recovery/recover`;
    },
  },

  /**
   * @memberof Endpoints/MemberAPI
   * @name member/v1/auth-types
   * @type GET
   * @summary request user SSO authentication info.
   */
  [privateTypes.GET_AUTH_TYPES]: {
    api,
    $$method: 'get',
    $$makeUrl(configs, params) {
      return `${getApiPath(configs)}/auth-types?email=${encodeURIComponent(params.email)}`;
    },
  },
};
