/* eslint-disable dot-notation */
import Vue from 'vue';
import axios from 'axios';
import { EndpointDecoder } from '@sword-health/ui-http-mapper';

import store from '@/store';
import { authStoreTypes, applicationStoreTypes } from '@/store/types';
import endpoints from '@/http/endpoints';
import { BASE_URLS } from '@/scripts/constants';
import '@/http/interceptors/request-interceptors';
import '@/http/interceptors/response-interceptors';

const unitName = store.getters[applicationStoreTypes.getters.UNIT_NAME];


axios.defaults.baseURL = BASE_URLS.BASE;
const swordMemberEndpointInterface = new EndpointDecoder(endpoints);

/** Set the default header for all requests */
function setAxiosHeaders(accessToken) {
  if (accessToken) {
    axios.defaults.headers.common['Authorization'] = accessToken;
    return;
  }
  delete axios.defaults.headers.common['Authorization'];
}

/**
 * Save the access and refresh token, allowing future manipulation of it in the endpoints files
 * For example: `headers: data => data.headers.authorization`,
 */
function setSwordHeaders(accessToken, refreshToken) {
  const swordHeaders = {
    authorization: { Authorization: accessToken },
    refreshAuthorization: { Authorization: refreshToken },
  };
  swordMemberEndpointInterface.dataUpdate = { headers: swordHeaders };
}

function setAuthHeaders() {
  const accessToken = store.getters[authStoreTypes.getters.GET_ACCESS_TOKEN];
  const refreshToken = store.getters[authStoreTypes.getters.GET_REFRESH_TOKEN];

  setAxiosHeaders(accessToken);
  setSwordHeaders(accessToken, refreshToken);
}

store.subscribe((mutation, state) => {
  const authTokensChanges = [
    authStoreTypes.mutations.SET_AUTH_DATA,
    authStoreTypes.mutations.SET_ACCESS_TOKEN,
    authStoreTypes.mutations.LOAD_STORED_AUTH_TOKENS,
  ];

  if (authTokensChanges.includes(mutation.type)) {
    setAuthHeaders();
  }

  const unitChanges = [
    applicationStoreTypes.mutations.SET_UNIT,
  ];

  if (unitChanges.includes(mutation.type)) {
    axios.defaults.headers.common['X-Unit'] = state.application.unit;
  }

  const deviceSourceChanges = [
    applicationStoreTypes.mutations.SET_DEVICE_SOURCE,
  ];

  if (deviceSourceChanges.includes(mutation.type)) {
    axios.defaults.headers.common['Sh-Source'] = state.application.deviceSource;
  }
});

function swordHttpClient(...args) {
  const endpointConfig = swordMemberEndpointInterface.$http(...args);
  if (!endpointConfig) {
    return null;
  }

  const { method, url, requestArgs } = endpointConfig;
  if (method === 'delete') {
    const [body, reqConfig] = requestArgs;
    reqConfig.data = body;
    return axios[method](url, reqConfig);
  }

  const config = requestArgs[1] || requestArgs[0];
  if (config) {
    config.headers = {
      ...config.headers,
      'X-Unit': unitName,
    };
  }

  return axios[method](url, ...requestArgs);
}

const GLOBAL_ALIAS = '$http';
Vue[GLOBAL_ALIAS] = swordHttpClient;
Object.defineProperties(Vue.prototype, {
  [GLOBAL_ALIAS]: {
    get() {
      return swordHttpClient;
    },
  },
});

export default swordHttpClient;
