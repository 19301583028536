import { env } from '@/config';

export const DEFAULT_LANGUAGE = env.VUE_APP_DEFAULT_LANGUAGE || 'en';

const DEFAULT_AVAILABLE_LANGUAGES = [DEFAULT_LANGUAGE];
let languagesFromEnv = null;
try {
  languagesFromEnv = env.VUE_APP_AVAILABLE_LANGUAGES;
} catch (error) {
  console.warn('failed to processing VUE_APP_AVAILABLE_LANGUAGES', env.VUE_APP_AVAILABLE_LANGUAGES, error);
}

export const AVAILABLE_LANGUAGES = languagesFromEnv || DEFAULT_AVAILABLE_LANGUAGES;

export const AVAILABLE_COUNTRIES_FOR_LANGUAGES = [
  { // United States of America - English
    code: 'US', language: 'en', selectionTranslationKey: 'language_en.selection', copy: 'language.en', languageLabel: 'English',
  },
  { // Portugal - Portuguese
    code: 'PT', language: 'pt', selectionTranslationKey: 'language_pt.selection', copy: 'language.pt', languageLabel: 'Português',
  },
  { // Spain - Spanish
    code: 'ES', language: 'es', selectionTranslationKey: 'language_es.selection', copy: 'language.es', languageLabel: 'Español',
  },
  { // France - French Canadian
    code: 'CA', language: 'fr-CA', selectionTranslationKey: 'language_fc.selection', copy: 'language.fc', languageLabel: 'Français Canadien',
  },
  { // United Kingdom - English
    code: 'GB', language: 'en-GB', selectionTranslationKey: 'language_en.selection', copy: 'language.en', languageLabel: 'English',
  },
];

export const PROFESSIONAL_LANGUAGES = [
  { // English
    code: 'EN', language: 'en', translationKey: 'language_en.selection', iconName: 'US',
  },
  { // Portuguese
    code: 'PT', language: 'pt', translationKey: 'language_pt.selection', iconName: 'PT',
  },
  { // Spanish
    code: 'ES', language: 'es', translationKey: 'language_es.selection', iconName: 'ES',
  },
  { // French
    code: 'FR', language: 'fr', translationKey: 'language.fr', iconName: 'FR',
  },
];

export const CAPTION_AVAILABLE_LANGUAGES = ['en', 'pt', 'es', 'fr'];
