import Vue from 'vue';
import { dynamicFormPrivateTypes as types } from '@/store/types';
import parseAnswers from '@/scripts/parseAnswers';

// STATE
const cleanState = {
  name: '',
  key: '',
  currentPageIndex: 0,
  pages: [],
  answers: {},
  answersWithoutErrors: {},
  version: undefined,
  filters: '',
  previousOutcomes: {},
};

const defaultState = () => ({
  ...cleanState,
});

// GETTERS
const _getters = {
  [types.getters.GET_FORM_KEY]: state => state.key,
  [types.getters.GET_FORM_NAME]: state => state.name,
  [types.getters.GET_FORM_PAGES]: state => state.pages || [],
  [types.getters.GET_FORM_FILTERS]: state => state.filters || '',
  [types.getters.GET_FORM_PREVIOUS_OUTCOMES]: state => state.previousOutcomes || {},
  [types.getters.GET_FORM_ANSWERS]: state => state.answers || {},
  [types.getters.GET_ANSWERS_WITHOUT_ERRORS]: state => state.answersWithoutErrors || {},
  [types.getters.GET_FORM_VERSION]: state => state.version || undefined,
  [types.getters.GET_FORM_PAGE_BY_INDEX]: state => index => {
    if (!state.pages) {
      return {};
    }

    return state.pages[index] || {};
  },
  [types.getters.GET_CURRENT_PAGE_INDEX]: state => state.currentPageIndex,
};

// MUTATIONS
const mutations = {
  [types.mutations.RESET_CLINICAL_FORM](state) {
    const defaults = defaultState();
    state.name = defaults.name;
    state.key = defaults.key;
    state.currentPageIndex = defaults.currentPageIndex;
    state.pages = defaults.pages;
    state.answers = defaults.answers;
    state.filters = defaults.filters;
    state.previousOutcomes = defaults.previousOutcomes;
    state.answersWithoutErrors = defaults.answersWithoutErrors;
  },
  [types.mutations.SET_CURRENT_PAGE_INDEX]: (state, index) => {
    state.currentPageIndex = index;
  },
  [types.mutations.SET_FORM_KEY]: (state, key) => {
    state.key = key;
  },
  [types.mutations.SET_FORM_NAME]: (state, name) => {
    state.name = name;
  },
  [types.mutations.SET_FORM_ANSWERS]: (state, answers) => {
    Object.keys(answers).forEach(respKey => {
      const pageKey = respKey.split('/')[0];
      const questionKey = respKey.split('/')[1];
      if (!state.answers) {
        state.answers = {};
      }

      if (!state.answers[pageKey]) {
        Vue.set(state.answers, pageKey, {});
      }

      Vue.set(state.answers[pageKey], questionKey, answers[respKey]);
    });
  },
  [types.mutations.SET_ANSWERS_WITHOUT_ERRORS]: (state, answers) => {
    state.answersWithoutErrors = answers;
  },
  [types.mutations.RESET_FORM_ANSWERS](state) {
    state.answers = {};
    state.answersWithoutErrors = {};
  },
  [types.mutations.SET_FORM_PAGES](state, pages) {
    state.pages = pages;
  },
  [types.mutations.SET_FORM_VERSION](state, version) {
    state.version = version;
  },
  [types.mutations.SET_FORM_FILTERS](state, filters) {
    state.filters = filters || '';
  },
  [types.mutations.SET_FORM_PREVIOUS_OUTCOMES](state, previousOutcomes) {
    Object.keys(previousOutcomes || {}).forEach(respKey => {
      if (!state.previousOutcomes) {
        state.previousOutcomes = {};
      }

      if (!state.previousOutcomes[respKey]) {
        Vue.set(state.previousOutcomes, respKey, {});
      }
    });
  },
};

// ACTIONS
const actions = {
  [types.actions.SET_ANSWERS_FOR_PAGE]({ commit }, { answers, pageKey }) {
    const pageAnswersArray = Object.entries(answers).filter(([k]) => k.includes(pageKey));
    const pageAnswers = Object.assign({}, ...pageAnswersArray.map(([k, v]) => ({ [k]: v })));

    commit(types.mutations.SET_FORM_ANSWERS, pageAnswers);
  },

  async [types.actions.SETUP_FORM]({ commit, dispatch }, {
    key, name, pages, answers, version, filters, previousOutcomes,
  }) {
    commit(types.mutations.SET_FORM_NAME, name);
    commit(types.mutations.SET_FORM_KEY, key);
    commit(types.mutations.SET_FORM_PAGES, pages);
    commit(types.mutations.SET_FORM_ANSWERS, answers);
    commit(types.mutations.SET_ANSWERS_WITHOUT_ERRORS, parseAnswers(answers));
    commit(types.mutations.SET_FORM_VERSION, version);
    commit(types.mutations.SET_FORM_FILTERS, filters);
    commit(types.mutations.SET_FORM_PREVIOUS_OUTCOMES, previousOutcomes);
    return dispatch(types.actions.PREFILL_ANSWERS);
  },

  [types.actions.PREFILL_ANSWERS]({ getters }, pages = null) {
    const p = pages || getters[types.getters.GET_FORM_PAGES];
    const answers = getters[types.getters.GET_FORM_ANSWERS];
    if (!Object.keys(answers).length) {
      return p;
    }

    const answeredPageKeys = Object.keys(answers).map(key => key.split('/')[0]);
    p.forEach(page => {
      const pageWithAnswers = answeredPageKeys.find(key => key === page.key);
      if (!pageWithAnswers) {
        return;
      }

      const { questions, key: pageKey } = page;
      const answersForPage = answers[pageKey];
      questions.forEach(question => {
        const answer = answersForPage[question.key];
        question.value = answer;
      });
    });
    return p;
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters: _getters,
  mutations,
  actions,
};
