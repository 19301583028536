import store from '@/store/index';
import { enrolmentStoreTypes } from '@/store/types';
import {
  evaluateGuards, trackingGuard, logoutGuard, clientGuard,
} from '@/router/guards';

export const correlationUUIDGuard = (to, from, next) => {
  const hasCorrelationUUID = !!store.getters[enrolmentStoreTypes.getters.GET_CORRELATION_UUID];
  if (!hasCorrelationUUID) {
    console.info('[registration] User without correlation uuid, sending to MemberStep');
    return next({ name: 'MemberStep', query: to.query, params: to.params });
  }
  return next();
};

export const eligibilityGuard = (to, from, next) => {
  const routeName = 'MemberStep';
  if (to.name === routeName) {
    return next();
  }
  return next({ name: routeName, query: to.query, params: to.params });
};

const registrationRoutes = [
  {
    name: 'Legacy',
    path: 'eligibility',
    redirect: to => ({ name: 'MemberStep', params: to.params, query: to.query }),
  },
  {
    name: 'MemberStep',
    path: 'register/member',
    component: () => import(/* webpackChunkName: "RegisterAccountStep" */ '../views/registration/MemberStep.vue'),
    beforeEnter: evaluateGuards([trackingGuard, logoutGuard, clientGuard]),
  },
  {
    name: 'InsuranceStep',
    path: 'register/insurance',
    component: () => import(/* webpackChunkName: "RegisterAccountStep" */ '../views/registration/InsuranceStep.vue'),
    beforeEnter: evaluateGuards([trackingGuard, logoutGuard, clientGuard]),
  },
  {
    path: 'register/subscriber',
    name: 'SubscriberStep',
    component: () => import(/* webpackChunkName: "RegisterAccountStep" */ '../views/registration/SubscriberStep.vue'),
    beforeEnter: evaluateGuards([trackingGuard, logoutGuard, clientGuard]),
  },
  {
    path: 'register/guardian',
    name: 'GuardianStep',
    component: () => import(/* webpackChunkName: "RegisterAccountStep" */ '../views/registration/GuardianStep.vue'),
    beforeEnter: evaluateGuards([trackingGuard, logoutGuard, clientGuard]),
  },
  {
    name: 'RegisterAccountStep',
    path: 'register',
    component: () => import(/* webpackChunkName: "RegisterAccountStep" */ '../views/registration/RegisterAccountStep.vue'),
    beforeEnter: evaluateGuards([trackingGuard, logoutGuard, clientGuard, correlationUUIDGuard]),
  },
  {
    name: 'IntegrationStep',
    path: 'eligibility/integration',
    component: () => import(/* webpackChunkName: "IntegrationStep" */ '../views/registration/IntegrationStep.vue'),
    props: true,
    beforeEnter: evaluateGuards([trackingGuard, logoutGuard, clientGuard]),
  },
  {
    name: 'RegistrationError',
    path: 'error',
    component: () => import(/* webpackChunkName: "RegistrationError" */ '../views/feedbacks/ErrorFeedback.vue'),
    props: true,
    beforeEnter: evaluateGuards([trackingGuard, logoutGuard, clientGuard]),
  },
  {
    name: 'RegistrationStepError',
    path: 'step-error-age',
    props: true,
    component: () => import(/* webpackChunkName: "RegistrationError" */ '../views/feedbacks/GenericFeedbackScreen.vue'),
    beforeEnter: evaluateGuards([trackingGuard, logoutGuard, clientGuard]),
  },
  {
    name: 'RegistrationSuccess',
    path: 'steps-success',
    props: {
      type: 'successRegister',
      title: 'eco_loading_woohoo',
      text: 'eco_loading_account_created',
    },
    component: () => import(/* webpackChunkName: "RegistrationError" */ '../views/feedbacks/GenericFeedbackScreen.vue'),
    beforeEnter: evaluateGuards([trackingGuard, logoutGuard, clientGuard]),
  },
  {
    name: 'RegistrationConnectionError',
    path: 'connection-error',
    component: () => import(/* webpackChunkName: "RegistrationError" */ '../views/feedbacks/ConnectionError.vue'),
    props: true,
    beforeEnter: evaluateGuards([trackingGuard, logoutGuard, clientGuard]),
  },
];

export default registrationRoutes;
