import { tools } from '@sword-health/ui-core';

const slotsSelectionOptionsFormatter = tools.calendarSlotFormatting;

function generateScheduleBody({ schedule }, therapistId) {
  return {
    timezone: schedule.timezone,
    date: schedule.dateWithOffset,
    // optional
    ...therapistId && { therapist_id: therapistId },
  };
}

function parseAppointmentData(apiData) {
  const schedule = apiData.appointment;
  return {
    ptFirstname: apiData.therapist_name,
    slots: apiData.availability,
    client: apiData.client,
    status: schedule.status,
    schedule: {
      time: slotsSelectionOptionsFormatter.formatTimeLabel(
        schedule.start_date,
        schedule.end_date,
        schedule.timezone,
        apiData.client?.configuration?.time_format,
      ),
      start: schedule.start_date,
      end: schedule.end_date,
      timezone: schedule.timezone,
      date: schedule.date,
      dateWithOffset: schedule.start_date,
    },
    language: apiData.metadata?.preferred_language,
    callType: apiData.metadata?.call_type,
    unit: schedule.unit,
  };
}

export default {
  parseAppointmentData,
  generateScheduleBody,
};
