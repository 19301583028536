import Vue, { reactive } from 'vue';

class PageInfo {
  constructor(
    title = '',
    subTitle = '',
    titleParams = null,
    subTitleParams = null,
    titleSlots = null,
    subTitleSlots = null,
    titleSpanColor = '',
    currentPageName = '',
    isTitleCentered = false,
    backHandler = null, // can null, function
    backRouteName = '',
    isLoading = false,
    loadTitle = '',
    loadSubTitle = '',
    headerBgColor = '',
    headerBgImage = '',
    headerClasses = '',
    pageTitleImageSrc = '',
    hideLogo = false,
    mainMenuOpen = false,
    withAnimation = false,
  ) {
    this.title = title;
    this.titleSlots = titleSlots;
    this.subTitle = subTitle;
    this.titleParams = titleParams;
    this.subTitleParams = subTitleParams;
    this.subTitleSlots = subTitleSlots;
    this.titleSpanColor = titleSpanColor;
    this.currentPageName = currentPageName;
    this.isTitleCentered = isTitleCentered;
    this.backHandler = backHandler;
    this.backRouteName = backRouteName;
    this.isLoading = isLoading;
    this.loadTitle = loadTitle;
    this.loadSubTitle = loadSubTitle;
    this.headerBgColor = headerBgColor;
    this.headerBgImage = headerBgImage;
    this.headerClasses = headerClasses;
    this.pageTitleImageSrc = pageTitleImageSrc;
    this.hideLogo = hideLogo;
    this.mainMenuOpen = mainMenuOpen;
    this.withAnimation = withAnimation;
  }

  showBackButton() {
    return !!this.backHandler || !!this.backRouteNameName;
  }
}

const pageInfo = reactive(new PageInfo());

Vue.prototype.$pageInfo = pageInfo;

function resetPageInfo() {
  Object.assign(pageInfo, new PageInfo());
}

export function usePageInfo() {
  return {
    pageInfo,
    resetPageInfo,
  };
}

export default PageInfo;
