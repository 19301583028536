import { BASE_URLS } from '@/scripts/constants';

// TYPES
export const MARKETING_ENDPOINT_NAMESPACE = 'marketing';
const getTypes = hasNamespace => {
  const prefix = hasNamespace ? `${MARKETING_ENDPOINT_NAMESPACE}/` : '';
  return {
    UNSUBSCRIBE_LEAD: `${prefix}unsubscribeLead`,
    UNSUBSCRIBE_ALL_UNITS_BY_LEAD: `${prefix}unsubscribeAllUnits`,
    SUBSCRIBE_LEAD_BY_UNIT: `${prefix}subscribeLeadByUnit`,
    GET_CHANNELS_BY_LEAD_ID: `${prefix}getChannelsByLeadID`,
    GET_LEAD_BY_ID: `${prefix}getLeadByID`,
  };
};

export const marketingEndpointsTypes = getTypes(true);
export const privateTypes = getTypes(false);

// COMMON API
const api = {
  url: BASE_URLS.API_MEMBER,
  version: '1',
};
const getApiPath = configs => `${configs.api.url}/v${configs.api.version}`;

export default {
  /**
   * @memberof Endpoints/MemberAPI
   * @type POST
   * @summary Unsubscription by unit
   */
  [privateTypes.UNSUBSCRIBE_LEAD]: {
    api: {
      ...api,
      version: '1',
    },
    $$method: 'put',
    $$makeUrl(configs, leadUUID) {
      return `${getApiPath(configs)}/marketing/unsubscribe/${leadUUID}`;
    },
  },


  /**
   * @memberof Endpoints/MemberAPI
   * @type POST
   * @summary Subscription by unit
   */
  [privateTypes.SUBSCRIBE_LEAD_BY_UNIT]: {
    api: {
      ...api,
      version: '1',
    },
    $$method: 'put',
    $$makeUrl(configs, leadUUID) {
      return `${getApiPath(configs)}/marketing/subscribe/${leadUUID}`;
    },
  },

  [privateTypes.GET_CHANNELS_BY_LEAD_ID]: {
    api: {
      ...api,
      version: '1',
    },
    $$method: 'get',
    $$makeUrl(configs, leadID) {
      return `${getApiPath(configs)}/marketing/subscriptions/${leadID}`;
    },
  },

  [privateTypes.GET_LEAD_BY_ID]: {
    api: {
      ...api,
      version: '1',
    },
    $$method: 'get',
    $$makeUrl(configs, leadID) {
      return `${getApiPath(configs)}/marketing/subscriptions/lead/${leadID}`;
    },
  },
};
