import { CONFIGURATIONS } from '@/scripts/constants/configurations';

export const UNITS = {
  BLOOM: 'bloom',
  MOVE: 'move',
  SWORD: 'sword',
  DPT_GO: 'dpt_go',
  MIND: 'mind',
  DPT: 'dpt',
};

export const UNIT_TO_CONFIGURATION = {
  [UNITS.SWORD]: CONFIGURATIONS.DPT,
  [UNITS.BLOOM]: CONFIGURATIONS.BLOOM,
  [UNITS.DPT_GO]: CONFIGURATIONS.DPT_GO,
  [UNITS.MOVE]: CONFIGURATIONS.MOVE,
  [UNITS.MIND]: CONFIGURATIONS.MIND,
};
