import {
  evaluateGuards, authGuard, trackingGuard, clientGuard, enrollmentGuard,
} from '@/router/guards';
import store from '@/store';
import { memberStoreTypes } from '@/store/types';

const defaultFormGuards = [authGuard, trackingGuard, clientGuard];

const virtualPTRoutes = [
  {
    name: 'DynamicOnboardingStep',
    path: 'step/:type/:key',
    component: () => import(/* webpackChunkName: "DynamicStep" */ '../views/dynamic-virtual-pt/DynamicStep.vue'),
    beforeEnter: evaluateGuards([authGuard, clientGuard, enrollmentGuard]),
    meta: {
      considerSupportChatEnabled: true,
    },
  },
  {
    name: 'VaginalAnatomySelectionForm',
    path: 'vaginal-anatomy',
    component: () => import(/* webpackChunkName: "VaginalAnatomySelection" */ '../views/virtual-pt/VaginalAnatomyStep.vue'),
    beforeEnter: evaluateGuards(defaultFormGuards),
  },
  {
    name: 'ConditionSelectionForm',
    path: 'condition',
    component: () => import(/* webpackChunkName: "ConditionSelection" */ '../views/virtual-pt/ConditionSelectionStep.vue'),
    beforeEnter: evaluateGuards(defaultFormGuards),
  },
  {
    name: 'DemographicsForm',
    path: 'demographics',
    component: () => import(/* webpackChunkName: "DemographicsInfo" */ '../views/virtual-pt/DemographicsStep.vue'),
    beforeEnter: evaluateGuards(defaultFormGuards),
    props: true,
  },
  {
    name: 'WorkerCompensationError',
    path: 'demographics/worker-compensation-error',
    props: {
      title: 'onboarding2_title_message_thanks',
      text: 'eco_workers_compensation_message',
      buttonText: 'onboarding_go_back',
      routeName: 'Programs',
    },
    component: () => import(/* webpackChunkName: "WorkerCompensationError" */ '../views/feedbacks/GenericFeedbackScreen.vue'),
    beforeEnter: evaluateGuards(defaultFormGuards),
  },
  {
    name: 'ClinicalDecisionForm',
    path: 'kit',
    component: () => import(/* webpackChunkName: "DynamicForms" */ '../views/virtual-pt/DynamicFormsSteps.vue'),
    beforeEnter: evaluateGuards(defaultFormGuards),
    props: true,
  },
  {
    name: 'ClinicalExclusionForm',
    path: 'clinical-exclusion',
    component: () => import(/* webpackChunkName: "DynamicForms" */ '../views/virtual-pt/DynamicFormsSteps.vue'),
    beforeEnter: evaluateGuards(defaultFormGuards),
    props: true,
  },
  {
    name: 'RoutineForm',
    path: 'routine',
    component: () => import(/* webpackChunkName: "DynamicForms" */ '../views/virtual-pt/DynamicFormsSteps.vue'),
    beforeEnter: evaluateGuards(defaultFormGuards),
    props: true,
  },
  {
    name: 'SurgeryForm',
    path: 'surgery',
    component: () => import(/* webpackChunkName: "DynamicForms" */ '../views/virtual-pt/DynamicFormsSteps.vue'),
    beforeEnter: evaluateGuards(defaultFormGuards),
    props: true,
  },
  {
    name: 'EverydayLifeForm',
    path: 'everyday-life',
    component: () => import(/* webpackChunkName: "DynamicForms" */ '../views/virtual-pt/DynamicFormsSteps.vue'),
    beforeEnter: evaluateGuards(defaultFormGuards),
    props: true,
  },
  {
    name: 'AnxietyForm',
    path: 'anxiety',
    component: () => import(/* webpackChunkName: "DynamicForms" */ '../views/virtual-pt/DynamicFormsSteps.vue'),
    beforeEnter: evaluateGuards(defaultFormGuards),
  },
  {
    name: 'DepressionForm',
    path: 'depression',
    component: () => import(/* webpackChunkName: "DynamicForms" */ '../views/virtual-pt/DynamicFormsSteps.vue'),
    beforeEnter: evaluateGuards(defaultFormGuards),
    props: true,
  },
  {
    name: 'ProductivityForm',
    path: 'productivity',
    component: () => import(/* webpackChunkName: "DynamicForms" */ '../views/virtual-pt/DynamicFormsSteps.vue'),
    beforeEnter: evaluateGuards(defaultFormGuards),
    props: true,
  },
  {
    name: 'PainForm',
    path: 'pain',
    component: () => import(/* webpackChunkName: "DynamicForms" */ '../views/virtual-pt/DynamicFormsSteps.vue'),
    beforeEnter: evaluateGuards(defaultFormGuards),
    props: true,
  },
  {
    name: 'CallPreferences',
    path: 'call/options',
    component: () => import(/* webpackChunkName: "CallPreferences" */ '../views/virtual-pt/CallPreferences.vue'),
  },
  {
    name: 'PainAreas',
    path: 'pain-areas',
    component: () => import(/* webpackChunkName: "ClinicalAssessments" */ '../views/virtual-pt/PainAreasStep.vue'),
  },
  {
    name: 'ExtraQuestions',
    path: 'extra-questions',
    component: () => import(/* webpackChunkName: "ClinicalAssessments" */ '../views/virtual-pt/ExtraQuestionsStep.vue'),
  },
  {
    name: 'PainDescription',
    path: 'pain-description',
    component: () => import(/* webpackChunkName: "ClinicalAssessments" */ '../views/virtual-pt/PainDescriptionStep.vue'),
  },
  {
    name: 'CallScheduleForm',
    path: 'schedule-call',
    component: () => import(/* webpackChunkName: "ScheduleVideoCall" */ '../views/virtual-pt/ScheduleCallStep.vue'),
    beforeEnter: async (to, from, next) => {
      try {
        await store.dispatch(memberStoreTypes.actions.GET_MEMBER);
      } finally {
        next();
      }
    },
  },
  {
    name: 'CallRescheduleForm',
    path: 'video-call/reschedule',
    component: () => import(/* webpackChunkName: "ScheduleVideoCall" */ '../views/virtual-pt/ScheduleCallStep.vue'),
  },
  {
    name: 'ConnectionError',
    path: 'video-call/connection-error',
    component: () => import(/* webpackChunkName: "ErrorFeedback" */ '../views/feedbacks/ConnectionError.vue'),
    beforeEnter: evaluateGuards([trackingGuard, authGuard, clientGuard]),
    props: true,
  },
  {
    name: 'PTSelectionForm',
    path: 'your-physical-therapist',
    component: () => import(/* webpackChunkName: "PTSelection" */ '../views/virtual-pt/PTSelectionStep.vue'),
    beforeEnter: evaluateGuards(defaultFormGuards),
  },
  {
    name: 'AddressForm',
    path: 'address',
    component: () => import(/* webpackChunkName: "AddressInfo" */ '../views/virtual-pt/AddressStep.vue'),
    beforeEnter: evaluateGuards(defaultFormGuards),
  },
  {
    name: 'VirtualPTSummary',
    path: 'summary',
    component: () => import(/* webpackChunkName: "VirtualPTSummary" */ '../views/virtual-pt/SummaryStep.vue'),
    beforeEnter: evaluateGuards(defaultFormGuards),
  },
  {
    name: 'EnrolmentError',
    path: 'error',
    component: () => import(/* webpackChunkName: "ErrorFeedback" */ '../views/feedbacks/ErrorFeedback.vue'),
    beforeEnter: evaluateGuards([trackingGuard, authGuard, clientGuard]),
    props: true,
  },
];

export default virtualPTRoutes;
