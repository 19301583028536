const parseAnswers = answers => {
  const pages = {};

  Object.keys(answers).forEach(key => {
    const pageKey = key.split('/')[0];
    const questionKey = key.split('/')[1];

    if (!pages[pageKey]) {
      pages[pageKey] = {};
    }

    pages[pageKey][questionKey] = answers[key];
  });

  return pages;
};

export default parseAnswers;
