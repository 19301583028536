import Vue from 'vue';
import { oneQuestionPerScreenPrivateTypes as types } from '@/store/types';

const defaultOneQuestionPerScreenState = () => ({
  lastSubmittedPages: {},
});

export default {
  namespaced: true,
  state: defaultOneQuestionPerScreenState(),
  getters: {
    [types.getters.GET_LAST_SUBMITTED_PAGE]: state => (type, key) => state.lastSubmittedPages[`${type}/${key}`],
  },
  mutations: {
    [types.mutations.UPDATE_LAST_SUBMITTED_PAGE](state, { type, key, page }) {
      Vue.set(state.lastSubmittedPages, `${type}/${key}`, page);
    },
  },
  actions: {
    [types.actions.UPDATE_LAST_SUBMITTED_PAGE]({ commit }, { type, key, page }) {
      commit(types.mutations.UPDATE_LAST_SUBMITTED_PAGE, { type, key, page });
    },
  },
};
