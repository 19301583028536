export default {
  name: 'Sword Health',
  logo: 'login/sword/logo-primary.svg',
  logoMobile: 'login/sword/logo-primary-mobile.svg',
  loginImage: 'login/sword/login-image.png',

  t: {
    // Summary
    eco_download_app_subtitle: 'eco_download_app_subtitle_mind',
    // PT SELECTION
    /* static */
    onboarding2_titles_choose_pt_one_option: 'onboarding2_titles_choose_pt_one_option_mind',
    onboarding2_titles_choose_pt: 'onboarding2_titles_choose_pt_mind',
    'onboarding_choose.PT_subtitle': 'onboarding_choose.PT_subtitle_mind',
    /* dynamic */
    onboarding2_stepper_choose_pt: 'onboarding2_stepper_choose_pt_mind',
    onboarding3_titles_choose_pt: 'onboarding3_titles_choose_pt_mind',
    onboarding3_subtitles_choose_pt: 'onboarding3_subtitles_choose_pt_mind',
    onboarding3_titles_choose_pt_one_option: 'onboarding3_titles_choose_pt_one_option_mind',
    // Address
    onboarding2_titles_address: 'onboarding2_titles_address_mind',
    onboarding2_subtitles_address: 'onboarding2_subtitles_address_mind',
    // Demographics
    onboarding2_titles_demographics: 'onboarding_demographic_title_mind',
    onboarding2_subtitles_demographics: 'onboarding_demographic_subtitle_mind',
    // ScheduleCallForm
    onboarding_question_keep_current_therapist: 'onboardingmind_question_keep_current_therapist',
    onboarding_keep_current_therapist_hint: 'onboardingmind_keep_current_therapist_hint',
  },
};
