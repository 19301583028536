import Vue from 'vue';
import Vuex from 'vuex';

import {
  AUTH_STORE_NAMESPACE,
  I18N_STORE_NAMESPACE,
  MEMBER_STORE_NAMESPACE,
  CLIENT_STORE_NAMESPACE,
  ENROLMENT_STORE_NAMESPACE,
  DYNAMIC_FORM_STORE_NAMESPACE,
  INVITATION_STORE_NAMESPACE,
  APPLICATION_STORE_NAMESPACE,
  clientStoreTypes,
  dynamicFormStoreTypes,
  TRIAGE_STORE_NAMESPACE,
  ONE_QUESTION_PER_SCREEN_STORE_NAMESPACE,
} from '@/store/types';
import auth from './modules/AuthStore';
import i18n from './modules/I18nStore';
import member from './modules/MemberStore';
import client from './modules/ClientStore';
import enrolment from './modules/EnrolmentStore';
import dynamicForm from './modules/DynamicFormStore';
import invitation from './modules/InvitationStore';
import appointment from './modules/AppointmentStore';
import reschedule from './modules/RescheduleStore';
import application from './modules/ApplicationStore';
import triage from './modules/TriageStore';
import oneQuestionPerScreenStore from './modules/OneQuestionPerScreenStore';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    [AUTH_STORE_NAMESPACE]: auth,
    [I18N_STORE_NAMESPACE]: i18n,
    [MEMBER_STORE_NAMESPACE]: member,
    [CLIENT_STORE_NAMESPACE]: client,
    [ENROLMENT_STORE_NAMESPACE]: enrolment,
    [DYNAMIC_FORM_STORE_NAMESPACE]: dynamicForm,
    [INVITATION_STORE_NAMESPACE]: invitation,
    [APPLICATION_STORE_NAMESPACE]: application,
    [TRIAGE_STORE_NAMESPACE]: triage,
    [ONE_QUESTION_PER_SCREEN_STORE_NAMESPACE]: oneQuestionPerScreenStore,
    appointment,
    reschedule,
  },
  actions: {
    reset({ commit }) {
      // dispatch('screening/reset');
      commit(clientStoreTypes.mutations.RESET_STATE);
      // commit('forms/resetState');
      // commit('therapists/reset');
      // commit(recoveryStoreTypes.mutations.RESET_STATE);
      commit(dynamicFormStoreTypes.mutations.RESET_CLINICAL_FORM);
      // commit('therapists/setRescheduling', false);
    },
  },
});
