export default {
  submitNPSFeedback: {
    api: {
      url: 'default',
      version: '1',
    },
    $$method: 'post',
    $$config: {},
    $$makeUrl(configs) {
      return `member/v${configs.api.version}/patient/nps/submit`;
    },
  },
};
