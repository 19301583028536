import auth, { AUTH_ENDPOINT_NAMESPACE } from './AuthEndpoints';
import account, { ACCOUNT_ENDPOINT_NAMESPACE } from './AccountEndpoints';
import enrolment, { ENROLMENT_ENDPOINT_NAMESPACE } from './EnrolmentEndpoints';
import memberRegistration, { MEMBER_REGISTRATION_ENDPOINT_NAMESPACE } from './MemberRegistrationEndpoints';
import client, { CLIENT_ENDPOINT_NAMESPACE } from './ClientEndpoints';
import invitation, { INVITATION_ENDPOINT_NAMESPACE } from './InvitationEndpoints';
import appointment from './AppointmentEndpoints';
import marketingIntegration, { MARKETING_ENDPOINT_NAMESPACE } from './MarketingIntegrationEndpoint';
import prevention from './PreventionEndpoints';
import nps from './NPSEndpoints';

/**
 * Enpoints targeting the Onboarding API
 * @namespace Endpoints/OnboardingAPI
 */

/**
 * Enpoints targeting the Member API
 * @namespace Endpoints/MemberAPI
 */

/**
 * Enpoints targeting the Onboarding Gateway
 * @namespace Endpoints/OnboardingUIGateway
 */

/**
 * Enpoints targeting the Eligibility API
 * @namespace Endpoints/EligibilityAPI
 */
export default {
  data: {
    apiUrls: {},
    headers: {},
  },
  modules: {
    [AUTH_ENDPOINT_NAMESPACE]: auth,
    [ACCOUNT_ENDPOINT_NAMESPACE]: account,
    [ENROLMENT_ENDPOINT_NAMESPACE]: enrolment,
    [CLIENT_ENDPOINT_NAMESPACE]: client,
    [MEMBER_REGISTRATION_ENDPOINT_NAMESPACE]: memberRegistration,
    [INVITATION_ENDPOINT_NAMESPACE]: invitation,
    [MARKETING_ENDPOINT_NAMESPACE]: marketingIntegration,
    prevention,
    appointment,
    nps,
  },
};
