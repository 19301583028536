import axios from 'axios';

/** Do something before request is sent */
const handleRequest = config => ({
  ...config,
  params: Object.assign({ bust: Date.now() }, config.params, { client: 'onboarding-ui' }),
});

/** Do something with request error */
const handleRequestError = error => Promise.reject(error);

axios.interceptors.request.use(handleRequest, handleRequestError);
