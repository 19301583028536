import config from '@/config';

const { unit, unitName } = config;

try {
  const head = document.getElementsByTagName('head')[0];
  const link32 = document.createElement('link');
  const link16 = document.createElement('link');
  document.title = unit.name;

  link32.rel = 'icon';
  link32.type = 'image/png';
  link32.sizes = '32x32';
  link32.href = `/v3/dist/${unitName}/favicon-32x32.png`;

  link16.rel = 'icon';
  link16.type = 'image/png';
  link16.sizes = '16x16';
  link16.href = `/v3/dist/${unitName}/favicon-16x16.png`;

  head.appendChild(link32);
  head.appendChild(link16);
} catch (error) {
  console.error(error);
}
