
import isWebview from 'is-ua-webview';
import Cookies from 'js-cookie';

const isSwordWebView = () => {
  const webviewCookie = Cookies.get('Is-Sword-Webview');
  return !!webviewCookie;
};

export {
  isSwordWebView,
  isWebview,
};
