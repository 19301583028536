export default {
  submitOnCallFeedback: {
    api: {
      url: 'default',
      version: '1',
    },
    $$method: 'post',
    $$config: {},
    $$makeUrl(configs) {
      return `member/v${configs.api.version}/prevention/on-call/feedback`;
    },
  },
};
