/* eslint-disable */

import Vue from 'vue';
import dayjs from 'dayjs';

// Languages available to dayJS
import 'dayjs/locale/pt'
import 'dayjs/locale/es'
import 'dayjs/locale/fr-ca'
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat';

Vue.use({
  install(Vue) {
    dayjs.extend(utc);
    dayjs.extend(localizedFormat);
    Vue.prototype.$date = dayjs;
    Vue.prototype.$date.prototype.isWeekend = function checkIfWeekend() {
      const isSaturday = this.day() === 6;
      const isSunday = this.day() === 0;
      return isSaturday || isSunday;
    };

    Vue.prototype.$date.prototype.nextDays = function getNextDays(timeFrameLength = 1, workingDaysOnly) {
      let offset = 0;
      const availableDates = [];
      for (let i = 0; i < timeFrameLength; i++) {
        let add = i + 1 + offset;
        let date = this.add(add, 'day');
        if (date.isWeekend() && workingDaysOnly) {
          do {
            offset++;
            add++;
            date = this.add(add, 'day');
          } while (date.isWeekend());
        }
        availableDates.push(date);
      }
      return availableDates;
    };
  },
});

export default dayjs;