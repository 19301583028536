import { BASE_URLS } from '@/scripts/constants';

// TYPES
export const MEMBER_REGISTRATION_ENDPOINT_NAMESPACE = 'memberRegistration';
const getTypes = hasNamespace => {
  const prefix = hasNamespace ? `${MEMBER_REGISTRATION_ENDPOINT_NAMESPACE}/` : '';
  return {
    GET_MEMBER_ELIGIBILITY: `${prefix}getMemberEligibility`,
    TRY_MEMBER_ELIGIBILITY: `${prefix}tryMemberEligibility`,
    SAVE_MEMBER_ELIGIBILITY: `${prefix}saveMemberEligibility`,
    GET_ACCOUNT_REGISTRATION: `${prefix}getAccountRegistration`,
    SAVE_ACCOUNT_REGISTRATION: `${prefix}saveAccountRegistration`,
    CHECK_EMAIL: `${prefix}checkEmail`,
  };
};

export const memberRegistrationEndpointsTypes = getTypes(true);
export const privateTypes = getTypes(false);

// COMMON API
const api = {
  url: BASE_URLS.API_MEMBER,
  version: '1',
};
const getApiPath = configs => `${configs.api.url}/v${configs.api.version}`;

export default {
  /**
   * @memberof Endpoints/MemberAPI
   * @name member/v1/enrollment/_enrolmentUUID_/eligibility
   * @type GET
   * @summary Get member eligibility data
   */
  [privateTypes.GET_MEMBER_ELIGIBILITY]: {
    api,
    $$method: 'get',
    $$makeUrl(configs, { enrolmentUUID }) {
      return `${getApiPath(configs)}/enrollment/${enrolmentUUID}/eligibility`;
    },
  },
  /**
   * @memberof Endpoints/MemberAPI
   * @name member/v1/check
   * @type POST
   * @summary Check if email is valid
   */
  [privateTypes.CHECK_EMAIL]: {
    api,
    $$method: 'post',
    $$makeUrl(configs) {
      return `${getApiPath(configs)}/check`;
    },
  },
  /**
   * @memberof Endpoints/MemberAPI
   * @name member/v1/eligibility/try
   * @type POST
   * @summary Try member eligibility data
   */
  [privateTypes.TRY_MEMBER_ELIGIBILITY]: {
    api,
    $$method: 'post',
    $$makeUrl(configs) {
      return `${getApiPath(configs)}/eligibility/try`;
    },
  },
  /**
   * @memberof Endpoints/MemberAPI
   * @name member/v1/enrollment/_enrolmentUUID_/eligibility
   * @type POST
   * @summary Save member eligibility data
   */
  [privateTypes.SAVE_MEMBER_ELIGIBILITY]: {
    api,
    $$method: 'post',
    $$makeUrl(configs, { enrolmentUUID }) {
      return `${getApiPath(configs)}/enrollment/${enrolmentUUID}/eligibility`;
    },
  },
  /**
   * @memberof Endpoints/MemberAPI
   * @name member/v1/account/eligibility/_correlationUUID_/register
   * @type GET
   * @summary Get account registration data
   */
  [privateTypes.GET_ACCOUNT_REGISTRATION]: {
    api,
    $$method: 'get',
    $$makeUrl(configs, { correlationUUID }) {
      return `${getApiPath(configs)}/account/eligibility/${correlationUUID}/register`;
    },
  },
  /**
   * @memberof Endpoints/MemberAPI
   * @name member/v1/account/eligibility/_correlationUUID_/register
   * @type POST
   * @summary Save account registration data
   */
  [privateTypes.SAVE_ACCOUNT_REGISTRATION]: {
    api,
    $$method: 'post',
    $$makeUrl(configs, { correlationUUID }) {
      return `${getApiPath(configs)}/account/eligibility/${correlationUUID}/register`;
    },
  },
};
