export default {
  fetchAppointmentData: {
    api: {
      url: 'default',
      version: '2',
    },
    $$method: 'get',
    $$config: {},
    $$makeUrl(configs, params) {
      const queryParams = `?keepPT=${params.keepPT}&source=${params.source}&tz=${params.tz}`;
      return `member/v${configs.api.version}/appointments/${params.appointmentUUID}/reschedule${queryParams}`;
    },
  },
  fetchAppointmentPTData: {
    api: {
      url: 'default',
      version: '2',
    },
    $$method: 'get',
    $$config: {},
    $$makeUrl(configs, params) {
      const queryParams = `?start=${params.start}&source=${params.source}`;
      return `member/v${configs.api.version}/appointments/${params.appointmentUUID}/reschedule/therapists${queryParams}`;
    },
  },
  submitReschedule: {
    api: {
      url: 'default',
      version: '2',
    },
    $$method: 'post',
    $$config: {},
    $$makeUrl(configs, params) {
      const queryParams = `?source=${params.source}`;
      return `member/v${configs.api.version}/appointments/${params.appointmentUUID}/reschedule${queryParams}`;
    },
  },
};
