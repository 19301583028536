import { BASE_URLS } from '@/scripts/constants';

// TYPES
export const ACCOUNT_ENDPOINT_NAMESPACE = 'account';
const getTypes = hasNamespace => {
  const prefix = hasNamespace ? `${ACCOUNT_ENDPOINT_NAMESPACE}/` : '';
  return {
    GET_MEMBER: `${prefix}getMember`,
    UPDATE_MEMBER: `${prefix}updateMember`,
    GET_WIZARD_INFO: `${prefix}getWizardInfo`,
    GET_ACCOUNT_CLIENT: `${prefix}getAccountClient`,
  };
};

export const types = getTypes(true);
const privateTypes = getTypes(false);

// COMMON API
const api = {
  url: BASE_URLS.API_MEMBER,
  version: '1',
};
const getApiPath = configs => `${configs.api.url}/v${configs.api.version}`;

// ENDPOINTS
export default {
  /**
   * @memberof Endpoints/MemberAPI
   * @name member/v1/me
   * @type GET
   * @summary Retrieve member's account information.
   */
  [privateTypes.GET_MEMBER]: {
    api,
    $$method: 'get',
    $$makeUrl(configs) {
      return `${getApiPath(configs)}/me`;
    },
  },
  /**
   * @memberof Endpoints/MemberAPI
   * @name member/v1/update
   * @type PATCH
   * @summary Update member account information.
   */
  [privateTypes.UPDATE_MEMBER]: {
    api,
    $$method: 'patch',
    $$makeUrl(configs) {
      return `${getApiPath(configs)}/update`;
    },
  },
  /**
   * @memberof Endpoints/MemberAPI
   * @name /v1/wizard
   * @type GET
   * @summary Get wizard info.
   */
  [privateTypes.GET_WIZARD_INFO]: {
    api,
    $$method: 'get',
    $$makeUrl(configs) {
      return `${getApiPath(configs)}/wizard`;
    },
  },
  /**
   * @memberof Endpoints/MemberAPI
   * @name /v1/account/client
   * @type GET
   * @summary Get account client.
   */
  [privateTypes.GET_ACCOUNT_CLIENT]: {
    api,
    $$method: 'get',
    $$makeUrl(configs) {
      return `${getApiPath(configs)}/account/client`;
    },
  },
};
