export default {
  name: 'Sword Health',
  logo: 'login/sword/logo-primary.svg',
  logoMobile: 'login/sword/logo-primary-mobile.svg',
  loginImage: 'login/sword/login-image.png',

  t: {
    // Condition Selection
    onboarding2_titles_condition: 'eco_area_concern_title',
    eco_area_concern_subtitle_interpolation: 'eco_area_concern_subtitle_interpolation_atlas',
    eco_area_concern_subtitle: 'eco_int_area_concern_subtitle',
    // Wizard
    eco_wizard_pagename: ' ',
    eco_title_on_call: 'eco_wizard_dpt_go_academy_title',
    eco_oncall_subtitle: ' ',
    onboarding2_welcome: 'eco_wizard_welcome_subtitle',
    eco_wizard_welcome_subtitle: ' ',
  },
};
