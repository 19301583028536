try {
  const addMaximumScaleToMetaViewport = () => {
    const el = document.querySelector('meta[name=viewport]');

    if (el !== null) {
      let content = el.getAttribute('content');
      content = [content, 'maximum-scale=1.0'].join(', ');
      el.setAttribute('content', content);
    }
  };

  const checkIsIOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  if (checkIsIOS()) addMaximumScaleToMetaViewport();
} catch (error) {
  console.log(error);
}
