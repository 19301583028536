const isEmpty = value => value === null || value === undefined || value.length === 0 || Object.keys(value).length === 0;

const isNotEmpty = value => !isEmpty(value);

const capitalize = value => {
  if (!value) return '';
  return value.toString().charAt(0).toUpperCase() + value.toString().slice(1);
};

function truncateText(text, maxChars) {
  if (typeof text !== 'string' || text === null || text === undefined) {
    return '';
  }

  return text.length > maxChars ? `${text.slice(0, maxChars)}...` : text;
}
// The animation delay used in dynamic forms. Make sure this value matches the CSS transition duration.
export const DYNAMIC_FORMS_ANIMATION_DELAY = 400;

/**
 * Scrolls to the top of the window after a delay.
 * @param {number} ms - The delay in milliseconds before scrolling to the top.
 * @param {Function} callback - The callback function to be executed after the delay.
 */
const delayedScrollToTop = (ms = DYNAMIC_FORMS_ANIMATION_DELAY, callback) => {
  setTimeout(() => {
    if (typeof cb === 'function') {
      callback();
    } else {
      window.scrollTo(0, 0);
    }
  }, ms);
};


export {
  isEmpty,
  isNotEmpty,
  capitalize,
  truncateText,
  delayedScrollToTop,
};
