import Cookies from 'js-cookie';
import store from '@/store';
import {
  authStoreTypes, clientStoreTypes, memberStoreTypes,
  enrolmentStoreTypes,
} from '@/store/types';
import { STORAGE_KEYS } from '@/scripts/constants';
import { sendTrackingPageView } from '@/scripts/tracking-util';

function evaluate(guards, to, from, next) {
  const guardsLeft = guards.slice(0); // clone the array so we do not accidentally modify it
  const nextGuard = guardsLeft.shift(); // Next guard to be evaluated

  if (nextGuard === undefined) {
    // If there are no more routes, call next
    next();
    return;
  }

  // callback replaces next() with a check, that recursively calls evaluate on the remaining guards
  const callback = nextArg => {
    if (nextArg === undefined) {
      evaluate(guardsLeft, to, from, next);
      return;
    }

    next(nextArg);
  };

  nextGuard(to, from, callback);
}

export function evaluateGuards(guards) {
  if (!Array.isArray(guards)) {
    throw new Error('You must specify an array of guards');
  }

  return (to, from, next) => evaluate(guards, to, from, next);
}

// GUARDS
export const authGuard = async (to, from, next) => {
  const isLoggedIn = () => store.getters[authStoreTypes.getters.IS_LOGGED_IN];

  // closure to fetch member info when missing and proceed
  const done = async () => {
    const accountUUID = store.getters[memberStoreTypes.getters.GET_ACCOUNT_ID];
    if (!accountUUID) {
      await store.dispatch(memberStoreTypes.actions.GET_MEMBER);
    }
    next();
  };

  // auth tokens loaded, proceed
  if (isLoggedIn()) {
    return done();
  }

  // no auth tokens, try loading from cookie
  store.commit(authStoreTypes.mutations.LOAD_STORED_AUTH_TOKENS);
  if (isLoggedIn()) {
    return done();
  }

  const accessToken = Cookies.get(STORAGE_KEYS.ACCESS_TOKEN);
  const refreshToken = Cookies.get(STORAGE_KEYS.REFRESH_TOKEN);
  // no access token, but have refresh token from cookie
  if (refreshToken && !accessToken) {
    await store.dispatch(authStoreTypes.actions.REFRESH_TOKENS);

    if (isLoggedIn()) {
      return done();
    }
  }

  // no tokens found, redirect to login
  console.info('[auth] user is not logged in, sending to Login');
  return next({
    name: 'Login',
    query: {
      ...to?.fullPath ? { redirect: to.fullPath } : null,
    },
  });
};

export function enrollmentGuard(to, from, next) {
  const hasEnrollment = store.getters[enrolmentStoreTypes.getters.HAS_ENROLLMENT];

  if (hasEnrollment) return next();

  return next({ name: 'Recovery' });
}

export const trackingGuard = (to, from, next) => {
  const currentFullUrl = `${window.location.origin}${to.fullPath}`;
  // Segment
  sendTrackingPageView({ path: currentFullUrl });
  return next();
};

export const logoutGuard = async (to, from, next) => {
  const isLoggedIn = store.getters[authStoreTypes.getters.IS_LOGGED_IN];
  if (isLoggedIn) {
    await store.dispatch(authStoreTypes.actions.LOGOUT);
  }
  return next();
};

// This flag only exists to update the client configs once the user enters the page.
// This is necessary because the clients entire information is saved
// in local storage, and we need to update the configs more often
// than the rest.
let clientNeedsToBeChecked = true;
export const clientGuard = async (to, from, next) => {
  const clientReference = to.params.clientRef;

  if (!clientReference) {
    return next({ name: 'noclient' });
  }

  // Checking if we already got current client in memory
  const settedClient = store.getters[clientStoreTypes.getters.GET_CLIENT_IDENTIFIERS];
  if (settedClient && settedClient.id && settedClient.reference === clientReference) {
    // We already have this patient, and the configs have been updated since the app has started
    if (!clientNeedsToBeChecked) {
      return next();
    }

    // We already have this patient, lets update the configs
    store
      .dispatch(clientStoreTypes.actions.UPDATE_CLIENT_CONFIGURATION, settedClient.reference)
      .then(() => {
        clientNeedsToBeChecked = false;
      })
      .catch(error => {
        console.warn('Failed to update client configuration', error);
      });
    return next();
  }

  // We need to fetch the new clients
  console.info('Resetting client and fetching new client:', clientReference);
  try {
    await store.dispatch(clientStoreTypes.actions.FETCH_CLIENT, clientReference);
    return next();
  } catch (error) {
    console.error(`Unable to fetch client ${clientReference} data`, error);
    return next({ name: 'noclient' });
  }
};

export const programStateGuard = (to, from, next) => {
  const selectedProgram = store.getters['screening/getSelectedProgram'];

  if (!selectedProgram) {
    console.info('[program-state] user is entering a program route without having a program selected:', selectedProgram, to.name);
    return next({ name: 'Recovery' });
  }

  if (to.meta?.program === selectedProgram) {
    return next();
  }

  console.info('[program-state] user is entering a program route that does not match their selected program');
  return next({ name: 'Recovery' });
};

export const dptGoGuard = async (to, from, next) => {
  const clientConfiguration = store.getters[clientStoreTypes.getters.GET_CLIENT_CONFIGURATION];

  if (to.name !== 'DptGoProgram' && clientConfiguration('dpt_go')?.enabled) {
    next({ name: 'DptGoProgram', params: to.params });
  } else {
    next();
  }
};

export const triageGuard = async (to, from, next) => {
  if (to.params.triageUUID || to.name === 'TriageError') {
    next();
  } else {
    next({ name: 'Programs' });
  }
};
