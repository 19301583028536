// TYPES
export const INVITATION_ENDPOINT_NAMESPACE = 'invitation';

export default {
  /**
   * @memberof Endpoints/OnboardingAPI
   * @name onboarding/v2/clients/_clientRef_/landing_pages/_invitationUUID_
   * @type GET
   * @summary Retrieve the info related to this invitation page. These are created in the Admin UI, in a clients page
   * @property clientRef {Param} Reference for the client corresponding to this invitation page.
   * @property invitationUUID {Param} UID for a given invitation page.
   */
  fetchInvitation: {
    api: {
      url: 'default',
      version: '2',
    },
    $$method: 'get',
    // $$config: {},
    $$makeUrl(configs, { clientReference, invitationUUID }) {
      return `onboarding/v${configs.api.version}/clients/${clientReference}/landing_pages/${invitationUUID}/`;
    },
  },
  /**
   * @memberof Endpoints/OnboardingAPI
   * @name onboarding/v1/invites
   * @type POST
   * @summary Submit an invitation corresponding to a new member to enroll in the onboarding
   */
  submitInvitation: {
    api: {
      url: 'default',
      version: '1',
    },
    $$method: 'post',
    $$config: {},
    $$makeUrl(configs) {
      return `onboarding/v${configs.api.version}/invites/`;
    },
  },
  /**
   * @memberof Endpoints/OnboardingAPI
   * @name onboarding/v2/clients/_clientRef_/referral/configs
   * @type GET
   * @summary Submite an invitation corresponding to a new member to enroll in the onboarding
   * @property clientRef {Param} Reference for the client corresponding to this invitation page.
   */
  getClinicalReferralRules: {
    api: {
      url: 'default',
      version: '2',
    },
    $$method: 'get',
    $$config: {},
    $$makeUrl(configs, { clientReference }) {
      return `onboarding/v${configs.api.version}/clients/${clientReference}/referral/configs`;
    },
  },
};
