import Vue from 'vue';
import { tools as tooling } from '@sword-health/ui-core';

import { getCountryStateOptions, USAStatesOpts } from '@sword-health/ui-core/dist/esm/definitions';
import { DEFAULT_LANGUAGE } from '@/scripts/constants/languages';
import { clientPrivateTypes as types, i18nStoreTypes, applicationStoreTypes } from '@/store/types';

import { clientEndpointsTypes } from '@/http/endpoints/ClientEndpoints';
import { STORAGE_KEYS } from '@/scripts/constants';

import zendesk from '@/plugins/zendesk';
import { CONFIGURATIONS } from '@/scripts/constants/configurations';

const clientStorage = new tooling.StorageInterface(localStorage);

const generateDefaultClientState = tryRecoverFromStorage => {
  if (tryRecoverFromStorage) {
    const storedClient = clientStorage.read(STORAGE_KEYS.CLIENT);
    if (storedClient) {
      return storedClient;
    }
  }
  return {
    id: null,
    poweredBy: false,
    identifier: null,
    logo: null,
    name: null,
    displayName: null,
    environment: null,
    configuration: {},
    payersList: [],
    referralConfigs: [],
  };
};

const initialClientState = generateDefaultClientState(true);
const unsupportedUSStateCodes = ['AS', 'FM', 'MH', 'MP', 'PW', 'VI'];

const removeUnsupportedUSStates = usStates => usStates.filter(states => !unsupportedUSStateCodes.includes(states.optValue));

export default {
  namespaced: true,
  state: {
    ...initialClientState,
  },
  getters: {
    [types.getters.GET_CLIENT_INFO]: state => state,
    [types.getters.GET_CLIENT_IDENTIFIERS]: state => ({
      id: state.id,
      reference: state.identifier,
    }),
    [types.getters.GET_CLIENT_CONFIGURATION]: state => prop => {
      if (!state.configuration) {
        return null;
      }

      return prop ? state.configuration[prop] : state.configuration;
    },
    [types.getters.GET_CLIENT_TOGGLES]: state => {
      const clientConfig = state.configuration || {};
      return clientConfig.toggles || {};
    },
    [types.getters.GET_COUNTRY]: (state, getters) => {
      const country = getters[types.getters.GET_CLIENT_CONFIGURATION](CONFIGURATIONS.COUNTRY);
      return country || 'us';
    },
    [types.getters.GET_CLIENT_LANGUAGE]: (state, getters) => {
      const language = getters[types.getters.GET_CLIENT_CONFIGURATION](CONFIGURATIONS.LANGUAGE);
      return language;
    },
    [types.getters.GET_CLIENT_AVAILABLE_LANGUAGES]: (state, getters) => {
      const languages = getters[types.getters.GET_CLIENT_CONFIGURATION]('available_languages');
      return languages || [];
    },
    [types.getters.GET_CLIENT_CALL_BUFFER]: (state, getters) => {
      const videoCall = getters.getClientConfiguration(CONFIGURATIONS.VIDEO_CALL);
      return videoCall.buffer;
    },
    [types.getters.GET_MININUM_SCHEDULING_DISTANCE_HOURS]: (state, getters) => getters.getClientConfiguration(
      CONFIGURATIONS.SCHEDULE.MIN_DISTANCE_HOURS,
    ),
    [types.getters.GET_CLIENT_NUMBER_DAYS_TO_SCHEDULE]: (state, getters) => getters.getClientConfiguration(CONFIGURATIONS.SCHEDULE.NUMBER_OF_DAYS),
    [types.getters.GET_CLIENT_CONDITIONS]: (state, getters) => {
      const conditions = getters.getClientConfiguration(CONFIGURATIONS.CONDITIONS);
      return conditions ? Object.values(conditions) : [];
    },
    [types.getters.GET_GEO_STATES_LIST]: state => {
      const clientConfig = state.configuration || {};
      const country = clientConfig.country || 'us';
      const stateOptions = getCountryStateOptions([country]);

      if (country === 'us') {
        return removeUnsupportedUSStates(stateOptions);
      }

      return stateOptions || removeUnsupportedUSStates(USAStatesOpts);
    },
    [types.getters.GET_REFERRAL_CONFIGS]: state => state.referralConfigs || [],
    [types.getters.GET_CLIENT_ELIGIBILITY_CONFIGS]: state => state.eligibilityConfigs || {},
    [types.getters.GET_DEFAULT_LEGAL_LINKS]: (state, getters, rootState, rootGetters) => {
      const language = rootGetters[i18nStoreTypes.getters.GET_LANGUAGE];
      const links = getters[types.getters.GET_DEFAULT_LEGAL_LINKS_BY_LANGUAGE];
      const defaultLanguageLinks = links[DEFAULT_LANGUAGE] || {};
      const linksForLanguage = links[language] || {};

      return {
        nonDiscrimination: linksForLanguage.nonDiscrimination || defaultLanguageLinks.nonDiscrimination,
        invitationTermsOfUse: linksForLanguage.invitationTermsOfUse || defaultLanguageLinks.invitationTermsOfUse,
        invitationNoticeOfPrivacy: linksForLanguage.invitationNoticeOfPrivacy || defaultLanguageLinks.invitationNoticeOfPrivacy,
        invitationNonDiscrimination: linksForLanguage.invitationNonDiscrimination || defaultLanguageLinks.invitationNonDiscrimination,
        minorConsent: linksForLanguage.minorConsent || defaultLanguageLinks.minorConsent,
        gorillaConsent: linksForLanguage.gorillaConsent || defaultLanguageLinks.gorillaConsent,
        irbConsent: linksForLanguage.irbConsent || defaultLanguageLinks.irbConsent,
        hipaaConsent: linksForLanguage.hipaaConsent || defaultLanguageLinks.hipaaConsent,
        termsAndConditions: linksForLanguage.termsAndConditions || defaultLanguageLinks.termsAndConditions,
        consentTelehealthServices: linksForLanguage.consentTelehealthServices || defaultLanguageLinks.consentTelehealthServices,
        privacyStatement: linksForLanguage.privacyStatement || defaultLanguageLinks.privacyStatement,
        wheelConsent: linksForLanguage.wheelConsent || defaultLanguageLinks.wheelConsent,
        wheelPrivacyStatement: linksForLanguage.wheelPrivacyStatement || defaultLanguageLinks.wheelPrivacyStatement,
        wheelInformedConsent: linksForLanguage.wheelInformedConsent || defaultLanguageLinks.wheelInformedConsent,
        openloopPrivacyPolicy: linksForLanguage.openloopPrivacyPolicy || defaultLanguageLinks.openloopPrivacyPolicy,
        openloopTermsAndConditions: linksForLanguage.openloopTermsAndConditions || defaultLanguageLinks.openloopTermsAndConditions,
        openloopTelehealth: linksForLanguage.openloopTelehealth || defaultLanguageLinks.openloopTelehealth,
        gdprConsent: linksForLanguage.gdprConsent || defaultLanguageLinks.gdprConsent,
      };
    },
    [types.getters.GET_DEFAULT_LEGAL_LINKS_BY_LANGUAGE]: (state, getters, rootState, rootGetters) => {
      const URLS = rootGetters[applicationStoreTypes.getters.URLS];

      return {
        en: {
          hipaaConsent: URLS.HIPAA_NOTICE_OF_PRIVACY_PRACTICES_URL,
          gorillaConsent: URLS.HEALTH_GORILLA_CONSENT_URL,
          irbConsent: URLS.IRB_CONSENT_URL,
          nonDiscrimination: URLS.NON_DISCRIMINATION,
          invitationTermsOfUse: URLS.INVITATION_TERMS_OF_USE,
          invitationNoticeOfPrivacy: URLS.INVITATION_PRIVACY_NOTICE,
          invitationNonDiscrimination: URLS.INVITATION_NON_DISCRIMINATION,
          minorConsent: URLS.MINOR_ELIGIBILITY_CONSENT_URL,
          termsAndConditions: URLS.TERMS_AND_CONDITIONS_URL,
          consentTelehealthServices: URLS.CONSENT_TO_TELEHEALTH_SERVICES_URL,
          privacyStatement: URLS.PRIVACY_STATEMENT_URL,
          wheelConsent: URLS.WHEEL_CONSENT_URL,
          wheelPrivacyStatement: URLS.WHEEL_PRIVACY_URL,
          wheelInformedConsent: URLS.WHEEL_INFORMED_CONSENT_URL,
          openloopPrivacyPolicy: URLS.OPENLOOP_PRIVACY_POLICY_URL,
          openloopTermsAndConditions: URLS.OPENLOOP_TERMS_AND_CONDITIONS_URL,
          openloopTelehealth: URLS.OPENLOOP_TELEHEALTH_URL,
          gdprConsent: URLS.GDPR_CONSENT_URL,
        },
        es: {
          hipaaConsent: URLS.ES_HIPAA_NOTICE_OF_PRIVACY_PRACTICES_URL,
          gorillaConsent: URLS.ES_GORILLA_CONSENT_URL || URLS.GORILLA_CONSENT_URL,
          irbConsent: URLS.ES_IRB_CONSENT_URL || URLS.IRB_CONSENT_URL,
          nonDiscrimination: URLS.ES_NON_DISCRIMINATION || URLS.NON_DISCRIMINATION,
          invitationTermsOfUse: URLS.ES_INVITATION_TERMS_OF_USE || URLS.INVITATION_TERMS_OF_USE,
          invitationNoticeOfPrivacy: URLS.ES_INVITATION_PRIVACY_NOTICE || URLS.INVITATION_PRIVACY_NOTICE,
          invitationNonDiscrimination: URLS.ES_INVITATION_NON_DISCRIMINATION || URLS.INVITATION_NON_DISCRIMINATION,
          minorConsent: URLS.ES_MINOR_ELIGIBILITY_CONSENT_URL || URLS.MINOR_ELIGIBILITY_CONSENT_URL,
          termsAndConditions: URLS.ES_TERMS_AND_CONDITIONS_URL || URLS.TERMS_AND_CONDITIONS_URL,
          consentTelehealthServices: URLS.ES_CONSENT_TO_TELEHEALTH_SERVICES_URL || URLS.CONSENT_TO_TELEHEALTH_SERVICES_URL,
          privacyStatement: URLS.ES_PRIVACY_STATEMENT_URL || URLS.PRIVACY_STATEMENT_URL,
          wheelConsent: URLS.ES_WHEEL_CONSENT_URL || URLS.WHEEL_CONSENT_URL,
          wheelPrivacyStatement: URLS.ES_WHEEL_PRIVACY_URL || URLS.WHEEL_PRIVACY_URL,
          wheelInformedConsent: URLS.ES_WHEEL_INFORMED_CONSENT_URL || URLS.WHEEL_INFORMED_CONSENT_URL,
          openloopPrivacyPolicy: URLS.ES_OPENLOOP_PRIVACY_POLICY_URL || URLS.OPENLOOP_PRIVACY_POLICY_URL,
          openloopTermsAndConditions: URLS.ES_OPENLOOP_TERMS_AND_CONDITIONS_URL || URLS.OPENLOOP_TERMS_AND_CONDITIONS_URL,
          openloopTelehealth: URLS.ES_OPENLOOP_TELEHEALTH_URL || URLS.OPENLOOP_TELEHEALTH_URL,
        },
        pt: {
          hipaaConsent: URLS.PT_HIPAA_NOTICE_OF_PRIVACY_PRACTICES_URL,
          gorillaConsent: URLS.PT_GORILLA_CONSENT_URL || URLS.GORILLA_CONSENT_URL,
          irbConsent: URLS.PT_IRB_CONSENT_URL || URLS.IRB_CONSENT_URL,
          nonDiscrimination: URLS.PT_NON_DISCRIMINATION || URLS.NON_DISCRIMINATION,
          invitationTermsOfUse: URLS.PT_INVITATION_TERMS_OF_USE || URLS.INVITATION_TERMS_OF_USE,
          invitationNoticeOfPrivacy: URLS.PT_INVITATION_PRIVACY_NOTICE || URLS.INVITATION_PRIVACY_NOTICE,
          invitationNonDiscrimination: URLS.PT_INVITATION_NON_DISCRIMINATION || URLS.INVITATION_NON_DISCRIMINATION,
          minorConsent: URLS.PT_MINOR_ELIGIBILITY_CONSENT_URL || URLS.MINOR_ELIGIBILITY_CONSENT_URL,
          termsAndConditions: URLS.PT_TERMS_AND_CONDITIONS_URL || URLS.TERMS_AND_CONDITIONS_URL,
          consentTelehealthServices: URLS.PT_CONSENT_TO_TELEHEALTH_SERVICES_URL || URLS.CONSENT_TO_TELEHEALTH_SERVICES_URL,
          privacyStatement: URLS.PT_PRIVACY_STATEMENT_URL || URLS.PRIVACY_STATEMENT_URL,
          wheelConsent: URLS.PT_WHEEL_CONSENT_URL || URLS.WHEEL_CONSENT_URL,
          wheelPrivacyStatement: URLS.PT_WHEEL_PRIVACY_URL || URLS.WHEEL_PRIVACY_URL,
          wheelInformedConsent: URLS.PT_WHEEL_INFORMED_CONSENT_URL || URLS.WHEEL_INFORMED_CONSENT_URL,
          openloopPrivacyPolicy: URLS.PT_OPENLOOP_PRIVACY_POLICY_URL || URLS.OPENLOOP_PRIVACY_POLICY_URL,
          openloopTermsAndConditions: URLS.PT_OPENLOOP_TERMS_AND_CONDITIONS_URL || URLS.OPENLOOP_TERMS_AND_CONDITIONS_URL,
          openloopTelehealth: URLS.PT_OPENLOOP_TELEHEALTH_URL || URLS.OPENLOOP_TELEHEALTH_URL,
          gdprConsent: URLS.PT_GDPR_CONSENT_URL || URLS.GDPR_CONSENT_URL,
        },
        fr: {
          hipaaConsent: URLS.FR_HIPAA_NOTICE_OF_PRIVACY_PRACTICES_URL,
          gorillaConsent: URLS.FR_GORILLA_CONSENT_URL || URLS.GORILLA_CONSENT_URL,
          irbConsent: URLS.FR_IRB_CONSENT_URL || URLS.IRB_CONSENT_URL,
          nonDiscrimination: URLS.FR_NON_DISCRIMINATION || URLS.NON_DISCRIMINATION,
          invitationTermsOfUse: URLS.FR_INVITATION_TERMS_OF_USE || URLS.INVITATION_TERMS_OF_USE,
          invitationNoticeOfPrivacy: URLS.FR_INVITATION_PRIVACY_NOTICE || URLS.INVITATION_PRIVACY_NOTICE,
          invitationNonDiscrimination: URLS.FR_INVITATION_NON_DISCRIMINATION || URLS.INVITATION_NON_DISCRIMINATION,
          minorConsent: URLS.FR_MINOR_ELIGIBILITY_CONSENT_URL || URLS.MINOR_ELIGIBILITY_CONSENT_URL,
          termsAndConditions: URLS.FR_TERMS_AND_CONDITIONS_URL || URLS.TERMS_AND_CONDITIONS_URL,
          consentTelehealthServices: URLS.FR_CONSENT_TO_TELEHEALTH_SERVICES_URL || URLS.CONSENT_TO_TELEHEALTH_SERVICES_URL,
          privacyStatement: URLS.FR_PRIVACY_STATEMENT_URL || URLS.PRIVACY_STATEMENT_URL,
          wheelConsent: URLS.FR_WHEEL_CONSENT_URL || URLS.WHEEL_CONSENT_URL,
          wheelPrivacyStatement: URLS.FR_WHEEL_PRIVACY_URL || URLS.WHEEL_PRIVACY_URL,
          wheelInformedConsent: URLS.FR_WHEEL_INFORMED_CONSENT_URL || URLS.WHEEL_INFORMED_CONSENT_URL,
          openloopPrivacyPolicy: URLS.FR_OPENLOOP_PRIVACY_POLICY_URL || URLS.OPENLOOP_PRIVACY_POLICY_URL,
          openloopTermsAndConditions: URLS.FR_OPENLOOP_TERMS_AND_CONDITIONS_URL || URLS.OPENLOOP_TERMS_AND_CONDITIONS_URL,
          openloopTelehealth: URLS.FR_OPENLOOP_TELEHEALTH_URL || URLS.OPENLOOP_TELEHEALTH_URL,
        },
        'fr-CA': {
          hipaaConsent: URLS.HIPAA_NOTICE_OF_PRIVACY_PRACTICES_URL,
          gorillaConsent: URLS.FR_CA_HEALTH_GORILLA_CONSENT_URL || URLS.GORILLA_CONSENT_URL,
          irbConsent: URLS.FR_CA_IRB_CONSENT_URL || URLS.IRB_CONSENT_URL,
          nonDiscrimination: URLS.FR_CA_NON_DISCRIMINATION || URLS.NON_DISCRIMINATION,
          invitationTermsOfUse: URLS.FR_CA_INVITATION_TERMS_OF_USE || URLS.INVITATION_TERMS_OF_USE,
          invitationNoticeOfPrivacy: URLS.FR_CA_INVITATION_PRIVACY_NOTICE || URLS.INVITATION_PRIVACY_NOTICE,
          invitationNonDiscrimination: URLS.FR_CA_INVITATION_NON_DISCRIMINATION || URLS.INVITATION_NON_DISCRIMINATION,
          minorConsent: URLS.MINOR_ELIGIBILITY_CONSENT_URL,
          termsAndConditions: URLS.FR_CA_TERMS_AND_CONDITIONS_URL || URLS.TERMS_AND_CONDITIONS_URL,
          consentTelehealthServices: URLS.FR_CA_CONSENT_TO_TELEHEALTH_SERVICES_URL || URLS.CONSENT_TO_TELEHEALTH_SERVICES_URL,
          privacyStatement: URLS.FR_CA_PRIVACY_STATEMENT_URL || URLS.PRIVACY_STATEMENT_URL,
          wheelConsent: URLS.WHEEL_CONSENT_URL,
          wheelPrivacyStatement: URLS.WHEEL_PRIVACY_URL,
          wheelInformedConsent: URLS.WHEEL_INFORMED_CONSENT_URL,
          gdprConsent: URLS.GDPR_CONSENT_URL,
          openloopPrivacyPolicy: URLS.FR_CA_OPENLOOP_PRIVACY_POLICY_URL || URLS.OPENLOOP_PRIVACY_POLICY_URL,
          openloopTermsAndConditions: URLS.FR_CA_OPENLOOP_TERMS_AND_CONDITIONS_URL || URLS.OPENLOOP_TERMS_AND_CONDITIONS_URL,
          openloopTelehealth: URLS.FR_CA_OPENLOOP_TELEHEALTH_URL || URLS.OPENLOOP_TELEHEALTH_URL,
        },
        'en-GB': {
          hipaaConsent: URLS.EN_GB_HIPAA_NOTICE_OF_PRIVACY_PRACTICES_URL,
          gorillaConsent: URLS.EN_GB_GORILLA_CONSENT_URL || URLS.GORILLA_CONSENT_URL,
          irbConsent: URLS.EN_GB_IRB_CONSENT_URL || URLS.IRB_CONSENT_URL,
          nonDiscrimination: URLS.EN_GB_NON_DISCRIMINATION || URLS.NON_DISCRIMINATION,
          invitationTermsOfUse: URLS.EN_GB_INVITATION_TERMS_OF_USE || URLS.INVITATION_TERMS_OF_USE,
          invitationNoticeOfPrivacy: URLS.EN_GB_INVITATION_PRIVACY_NOTICE || URLS.INVITATION_PRIVACY_NOTICE,
          invitationNonDiscrimination: URLS.EN_GB_INVITATION_NON_DISCRIMINATION || URLS.INVITATION_NON_DISCRIMINATION,
          minorConsent: URLS.EN_GB_MINOR_ELIGIBILITY_CONSENT_URL || URLS.MINOR_ELIGIBILITY_CONSENT_URL,
          termsAndConditions: URLS.EN_GB_TERMS_AND_CONDITIONS_URL || URLS.TERMS_AND_CONDITIONS_URL,
          consentTelehealthServices: URLS.EN_GB_CONSENT_TO_TELEHEALTH_SERVICES_URL || URLS.CONSENT_TO_TELEHEALTH_SERVICES_URL,
          privacyStatement: URLS.EN_GB_PRIVACY_STATEMENT_URL || URLS.PRIVACY_STATEMENT_URL,
          wheelConsent: URLS.EN_GB_WHEEL_CONSENT_URL || URLS.WHEEL_CONSENT_URL,
          wheelPrivacyStatement: URLS.EN_GB_WHEEL_PRIVACY_URL || URLS.WHEEL_PRIVACY_URL,
          wheelInformedConsent: URLS.EN_GB_WHEEL_INFORMED_CONSENT_URL || URLS.WHEEL_INFORMED_CONSENT_URL,
          gdprConsent: URLS.EN_GB_GDPR_CONSENT_URL || URLS.GDPR_CONSENT_URL,
          openloopPrivacyPolicy: URLS.EN_GB_OPENLOOP_PRIVACY_POLICY_URL || URLS.OPENLOOP_PRIVACY_POLICY_URL,
          openloopTermsAndConditions: URLS.EN_GB_OPENLOOP_TERMS_AND_CONDITIONS_URL || URLS.OPENLOOP_TERMS_AND_CONDITIONS_URL,
          openloopTelehealth: URLS.EN_GB_OPENLOOP_TELEHEALTH_URL || URLS.OPENLOOP_TELEHEALTH_URL,
        },
      };
    },
    [types.getters.GET_CLIENT_LEGAL_LINKS]: (state, getters, rootState, rootGetters) => {
      const language = rootGetters[i18nStoreTypes.getters.GET_LANGUAGE];
      const clientLegalLinks = getters[types.getters.GET_CLIENT_CONFIGURATION](CONFIGURATIONS.LEGAL_LINKS) || {};
      const defaultLegalLinks = getters[types.getters.GET_DEFAULT_LEGAL_LINKS];
      const clientLegalLinksForLanguage = clientLegalLinks[language] || {};

      return {
        nonDiscrimination: clientLegalLinksForLanguage.non_discrimination || defaultLegalLinks.nonDiscrimination,
        gorillaConsent: clientLegalLinksForLanguage.health_gorilla_consent || defaultLegalLinks.gorillaConsent,
        irbConsent: clientLegalLinksForLanguage.irb_consent || defaultLegalLinks.irbConsent,
        hipaaConsent: clientLegalLinksForLanguage.hipaa_consent || defaultLegalLinks.hipaaConsent,
        termsAndConditions: clientLegalLinksForLanguage.terms_and_conditions || defaultLegalLinks.termsAndConditions,
        consentTelehealthServices: clientLegalLinksForLanguage.telehealth_consent || defaultLegalLinks.consentTelehealthServices,
        privacyStatement: clientLegalLinksForLanguage.privacy_statement || defaultLegalLinks.privacyStatement,
        minorConsent: clientLegalLinksForLanguage.minorConsent || defaultLegalLinks.minorConsent,
        gdprConsent: defaultLegalLinks.gdprConsent,
        wheelConsent: defaultLegalLinks.wheelConsent,
        wheelPrivacyStatement: defaultLegalLinks.wheelPrivacyStatement,
        wheelInformedConsent: defaultLegalLinks.wheelInformedConsent,
        openloopPrivacy: defaultLegalLinks.openloopPrivacyPolicy,
        openloopTermsAndConditions: defaultLegalLinks.openloopTermsAndConditions,
        openloopTelehealth: defaultLegalLinks.openloopTelehealth,
      };
    },
  },
  mutations: {
    [types.mutations.RESET_STATE](state) {
      clientStorage.delete(STORAGE_KEYS.CLIENT);
      Object.assign(state, generateDefaultClientState(false));
    },
    [types.mutations.SET_CLIENT_IDENTIFIERS]: (state, { id, reference }) => {
      state.id = id;
      state.identifier = reference;
    },
    [types.mutations.SET_CLIENT]: (state, clientData) => {
      state.id = clientData.id;
      state.poweredBy = clientData.powered_by;
      state.identifier = clientData.reference;
      state.logo = clientData.image_url;
      state.name = clientData.name;
      state.displayName = clientData.display_name;
      state.environment = clientData.environment;
      state.configuration = clientData.configuration;
      state.referralConfigs = clientData.referral_configs;
      state.eligibilityConfigs = clientData.eligibility_configs;
      clientStorage.write(STORAGE_KEYS.CLIENT, state);
    },
    [types.mutations.SET_CLIENT_CONFIGURATION]: (state, { clientConfiguration, referralConfigs, eligibilityConfigs }) => {
      state.configuration = clientConfiguration;
      state.referralConfigs = referralConfigs;
      state.eligibilityConfigs = eligibilityConfigs;
      clientStorage.write(STORAGE_KEYS.CLIENT, state);
    },
  },
  actions: {
    [types.actions.SETUP_CLIENT]({ commit, dispatch }, clientData) {
      commit(types.mutations.SET_CLIENT, clientData);
      dispatch(i18nStoreTypes.actions.UPDATE_APP_LANGUAGE, undefined, { root: true });
    },
    async [types.actions.FETCH_CLIENT]({ dispatch }, clientRef) {
      const { data: client } = await Vue.$http(clientEndpointsTypes.FETCH_CLIENT, clientRef);

      zendesk.setZendeskStrictMode(client?.data_access === 'strict');

      await dispatch(types.actions.SETUP_CLIENT, client);
      return client;
    },
    async [types.actions.UPDATE_CLIENT_CONFIGURATION]({ commit, dispatch }, clientRef) {
      const { data: client } = await Vue.$http(clientEndpointsTypes.FETCH_CLIENT, clientRef);
      if (!client || !client.configuration) {
        return;
      }

      zendesk.setZendeskStrictMode(client.data_access === 'strict');

      commit(types.mutations.SET_CLIENT_CONFIGURATION, {
        clientConfiguration: client.configuration,
        referralConfigs: client.referral_configs,
        eligibilityConfigs: client.eligibility_configs,
      });
      dispatch(i18nStoreTypes.actions.UPDATE_APP_LANGUAGE, undefined, { root: true });
    },
  },
};
