import { BASE_URLS } from '@/scripts/constants';

// TYPES
export const ENROLMENT_ENDPOINT_NAMESPACE = 'enrolment';
const getTypes = hasNamespace => {
  const prefix = hasNamespace ? `${ENROLMENT_ENDPOINT_NAMESPACE}/` : '';
  return {
    GET_ENROLMENT: `${prefix}getEnrolment`,
    START_NEW_PROGRAM: `${prefix}startNewProgram`,
    GET_DEMOGRAPHICS: `${prefix}getDemographics`,
    SUBMIT_DEMOGRAPHICS: `${prefix}submitDemographics`,
    GET_CONDITION: `${prefix}getCondition`,
    SUBMIT_CONDITION: `${prefix}submitCondition`,
    GET_CLINICAL_FORM: `${prefix}getClinicalForm`,
    SAVE_CLINICAL_FORM: `${prefix}saveClinicalForm`,
    SUBMIT_CLINICAL_FORM: `${prefix}submitClinicalForm`,
    GET_CALL_PREFERENCES_DECISION: `${prefix}getCallPreferencesDecision`,
    SUBMIT_CALL_PREFERENCES_DECISION: `${prefix}submitCallPreferencesDecision`,
    GET_PAIN_AREAS: `${prefix}getPainAreas`,
    SUBMIT_PAIN_AREAS: `${prefix}submitPainAreas`,
    GET_PAIN_DESCRIPTIONS: `${prefix}getPainDescription`,
    SUBMIT_PAIN_DESCRIPTIONS: `${prefix}submitPainDescription`,
    GET_SCHEDULE: `${prefix}getSchedule`,
    GET_SLOTS: `${prefix}getSlots`,
    SUBMIT_SCHEDULE: `${prefix}submitSchedule`,
    SAVE_PREFERRED_SLOT: `${prefix}savePreferredSlot`,
    GET_AVAILABLE_THERAPISTS: `${prefix}getAvailableTherapists`,
    GET_THERAPIST: `${prefix}getTherapist`,
    SUBMIT_THERAPIST: `${prefix}submitTherapist`,
    GET_CITY_BY_ZIPCODE: `${prefix}getCityByZipCode`,
    GET_ADDRESS: `${prefix}getAddress`,
    SUBMIT_ADDRESS: `${prefix}submitAddress`,
    GET_ENROLMENT_SUMMARY: `${prefix}getEnrolmentSummary`,
    FINISH_ENROLMENT_PROGRAM: `${prefix}finishEnrolmentProgram`,
    RECOVER_ENROLMENT: `${prefix}recoveryEnrolment`,
    VALIDATE_INVITE: `${prefix}validateInvite`,
    CHECK_ENROLMENT_AVAILABILITY: `${prefix}checkEnrolmentAvailability`,
    GET_NAVIGATOR_INFO: `${prefix}getNavigatorInfo`,
    SAVE_PROGRAM: `${prefix}saveProgram`,
    GET_ACCOUNT_ELIGIBILITY: `${prefix}getAccountEligibility`,
    UPDATE_ACCOUNT_ELIGIBILITY: `${prefix}updateAccountEligibility`,
    SUBMIT_VAGINAL_ANATOMY: `${prefix}submitVaginalAnatomy`,
    SAVE_INTEREST: `${prefix}saveInterest`,
    START_ENROLMENT: `${prefix}startEnrolment`,
    GET_SPECIAL_CONDITIONS: `${prefix}getSpecialConditions`,
    SUBMIT_SPECIAL_CONDITION: `${prefix}submitSpecialCondition`,
  };
};

export const enrolmentEndpointTypes = getTypes(true);
export const privateTypes = getTypes(false);

// COMMON API
const api = {
  url: BASE_URLS.API_MEMBER,
  version: '1',
};
const getApiPath = configs => `${configs.api.url}/v${configs.api.version}`;

export default {
  /**
   * @memberof Endpoints/MemberAPI
   * @name member/v1/enrollment
   * @type GET
   * @summary Get the user's current enrolment
   */
  [privateTypes.GET_ENROLMENT]: {
    api,
    $$method: 'get',
    $$makeUrl(configs) {
      return `${getApiPath(configs)}/enrollment`;
    },
  },
  /**
   * @memberof Endpoints/MemberAPI
   * @name member/v1/navigator
   * @type GET
   * @summary Get navigator info.
   */
  [privateTypes.GET_NAVIGATOR_INFO]: {
    api,
    $$method: 'get',
    $$makeUrl(configs) {
      return `${getApiPath(configs)}/navigator`;
    },
  },
  /**
   * @memberof Endpoints/MemberAPI
   * @name member/v2/enrollment/
   * @type POST
   * @summary Save program
   */
  [privateTypes.SAVE_PROGRAM]: {
    api: {
      url: api.url,
      version: '2',
    },
    $$method: 'post',
    $$makeUrl(configs) {
      return `${getApiPath(configs)}/enrollment`;
    },
  },
  /**
   * @memberof Endpoints/MemberAPI
   * @name member/v1/enrollment/new-program
   * @type POST
   * @summary Start new program, when switching from preventive to virtual-pt, for example
   * @returns new enrolment id
   */
  [privateTypes.START_NEW_PROGRAM]: {
    api,
    $$method: 'post',
    $$makeUrl(configs) {
      return `${getApiPath(configs)}/enrollment/new-program`;
    },
  },
  /**
   * @memberof Endpoints/MemberAPI
   * @name member/v1/enrollment/_uuid_/demographics
   * @type GET
   * @summary Retrieve users info for the demographics step, for a specific enrolment.
   * @property uuid {Param} Enrollment ID
   */
  [privateTypes.GET_DEMOGRAPHICS]: {
    api,
    $$method: 'get',
    $$makeUrl(configs, { uuid }) {
      return `${getApiPath(configs)}/enrollment/${uuid}/demographics`;
    },
  },
  /**
   * @memberof Endpoints/MemberAPI
   * @name member/v1/enrollment/_uuid_/demographics
   * @type POST
   * @summary Submit user input info for the demographics step, for a specific enrolment.
   * @property uuid {Param} Enrollment ID
   */
  [privateTypes.SUBMIT_DEMOGRAPHICS]: {
    api,
    $$method: 'post',
    $$makeUrl(configs, { uuid }) {
      return `${getApiPath(configs)}/enrollment/${uuid}/demographics`;
    },
  },
  /**
   * @memberof Endpoints/MemberAPI
   * @name member/v1/enrollment/_uuid_/condition
   * @type POST
   * @summary Submit the user's selected clinical condition
   */
  [privateTypes.SUBMIT_CONDITION]: {
    api,
    $$method: 'post',
    $$makeUrl(configs, { uuid }) {
      return `${getApiPath(configs)}/enrollment/${uuid}/condition`;
    },
  },
  /**
   * @memberof Endpoints/MemberAPI
   * @name member/v1/enrollment/_uuid_/condition
   * @type GET
   * @summary Get the user's selected clinical condition, if any
   */
  [privateTypes.GET_CONDITION]: {
    api,
    $$method: 'get',
    $$makeUrl(configs, { uuid }) {
      return `${getApiPath(configs)}/enrollment/${uuid}/condition`;
    },
  },
  /**
   * @memberof Endpoints/MemberAPI
   * @name member/v1/enrollment/_uuid_/condition/clinical-forms
   * @type GET
   * @summary Retrieve all information necessary to fill the enrolments clinical form
   */
  [privateTypes.GET_CLINICAL_FORM]: {
    api,
    $$method: 'get',
    $$makeUrl(configs, { uuid }) {
      return `${getApiPath(configs)}/enrollment/${uuid}/condition/clinical-forms`;
    },
  },
  /**
   * @memberof Endpoints/MemberAPI
   * @name member/v1/enrollment/_uuid_/condition/clinical-forms
   * @type PATCH
   * @summary Saves (patch) a users clinical forms progress
   */
  [privateTypes.SAVE_CLINICAL_FORM]: {
    api,
    $$method: 'patch',
    $$makeUrl(configs, { uuid }) {
      return `${getApiPath(configs)}/enrollment/${uuid}/condition/clinical-forms`;
    },
  },
  /**
   * @memberof Endpoints/MemberAPI
   * @name member/v1/enrollment/_uuid_/condition/clinical-forms
   * @type POST
   * @summary Submits a users clinical form making it impossible to change any answer afterwards
   */
  [privateTypes.SUBMIT_CLINICAL_FORM]: {
    api,
    $$method: 'post',
    $$makeUrl(configs, { uuid }) {
      return `${getApiPath(configs)}/enrollment/${uuid}/condition/clinical-forms`;
    },
  },
  /**
   * @memberof Endpoints/MemberAPI
   * @name member/v1/enrollment/_uuid_/decision/call-preferences
   * @type GET
   * @summary Get a enrollment call-preferences decision
   */
  [privateTypes.GET_CALL_PREFERENCES_DECISION]: {
    api,
    $$method: 'get',
    $$makeUrl(configs, { uuid }) {
      return `${getApiPath(configs)}/enrollment/${uuid}/decisions/call-preferences`;
    },
  },
  /**
  * @memberof Endpoints/MemberAPI
  * @name member/v1/enrollment/_uuid_/decisions/call-preferences
  * @type PUT
  * @summary Submits a members call-preferences decision
  */
  [privateTypes.SUBMIT_CALL_PREFERENCES_DECISION]: {
    api,
    $$method: 'put',
    $$makeUrl(configs, { uuid }) {
      return `${getApiPath(configs)}/enrollment/${uuid}/decisions/call-preferences`;
    },
  },
  /**
  * @memberof Endpoints/MemberAPI
  * @name member/v1/enrollment/_uuid_/condition/pain-areas
  * @type GET
  * @summary Get a enrollment possible pain areas
  */
  [privateTypes.GET_PAIN_AREAS]: {
    api,
    $$method: 'get',
    $$makeUrl(configs, { uuid }) {
      return `${getApiPath(configs)}/enrollment/${uuid}/condition/pain-areas`;
    },
  },
  /**
  * @memberof Endpoints/MemberAPI
  * @name member/v1/enrollment/_uuid_/condition/pain-areas
  * @type PUT
  * @summary Submits a members pain areas
  */
  [privateTypes.SUBMIT_PAIN_AREAS]: {
    api,
    $$method: 'put',
    $$makeUrl(configs, { uuid }) {
      return `${getApiPath(configs)}/enrollment/${uuid}/condition/pain-areas`;
    },
  },
  /**
  * @memberof Endpoints/MemberAPI
  * @name member/v1/enrollment/_uuid_/condition/pain-descriptions
  * @type GET
  * @summary Get a enrollment possible pain descriptions
  */
  [privateTypes.GET_PAIN_DESCRIPTIONS]: {
    api,
    $$method: 'get',
    $$makeUrl(configs, { uuid }) {
      return `${getApiPath(configs)}/enrollment/${uuid}/condition/pain-descriptions`;
    },
  },
  /**
  * @memberof Endpoints/MemberAPI
  * @name member/v1/enrollment/_uuid_/condition/pain-descriptions
  * @type PUT
  * @summary Submits a members pain descriptions
  */
  [privateTypes.SUBMIT_PAIN_DESCRIPTIONS]: {
    api,
    $$method: 'put',
    $$makeUrl(configs, { uuid }) {
      return `${getApiPath(configs)}/enrollment/${uuid}/condition/pain-descriptions`;
    },
  },
  /**
   * @memberof Endpoints/MemberAPI
   * @name member/v1/enrollment/_uuid_/schedule
   * @type GET
   * @summary Get the user's selected video call schedule
   */
  [privateTypes.GET_SCHEDULE]: {
    api,
    $$method: 'get',
    $$makeUrl(configs, { uuid }) {
      return `${getApiPath(configs)}/enrollment/${uuid}/schedule`;
    },
  },
  /**
  * @memberof Endpoints/MemberAPI
  * @name member/v1/enrollment/_uuid_/schedule/therapist
  * @type GET
  * @summary Get the user's selected therapist, if any
  */
  [privateTypes.GET_THERAPIST]: {
    api,
    $$method: 'get',
    $$makeUrl(configs, { uuid }) {
      return `${getApiPath(configs)}/enrollment/${uuid}/schedule/therapist`;
    },
  },
  /**
   * @memberof Endpoints/MemberAPI
   * @name member/v1/enrollment/_uuid_/schedule/slots
   * @type GET
   * @summary Get the video call slots that are available for the user to schedule a video call
   */
  [privateTypes.GET_SLOTS]: {
    api,
    $$method: 'get',
    $$makeUrl(configs, {
      uuid, languages, keepPT, therapistID, tz,
    }) {
      let queryParams = `?keepPT=${keepPT}`;

      if (languages && languages.length && !keepPT) {
        queryParams += `&lang=${languages.join(',')}`;
      }

      if (therapistID) {
        queryParams += `&therapist_id=${therapistID}`;
      }

      if (tz) {
        queryParams += `&tz=${tz}`;
      }

      return `${getApiPath(configs)}/enrollment/${uuid}/schedule/slots/${queryParams}`;
    },
  },
  /**
   * @memberof Endpoints/MemberAPI
   * @name member/v1/enrollment/_uuid_/schedule/slots
   * @type POST
   * @summary Submit the user's selected slot for the video call schedule
   */
  [privateTypes.SUBMIT_SCHEDULE]: {
    api,
    $$method: 'post',
    $$makeUrl(configs, { uuid }) {
      return `${getApiPath(configs)}/enrollment/${uuid}/schedule/slots`;
    },
  },

  [privateTypes.SAVE_PREFERRED_SLOT]: {
    api,
    $$method: 'post',
    $$makeUrl(configs, { uuid }) {
      return `${getApiPath(configs)}/enrollment/${uuid}/schedule/preferred-slot`;
    },
  },
  /**
   * @memberof Endpoints/MemberAPI
   * @name member/v1/enrollment/_uuid_/schedule/therapists
   * @type GET
   * @summary Retrieve information of the therapists that can be assigned to this member for treatment
   */
  [privateTypes.GET_AVAILABLE_THERAPISTS]: {
    api,
    $$method: 'get',
    $$makeUrl(configs, { uuid }) {
      return `${getApiPath(configs)}/enrollment/${uuid}/schedule/therapists`;
    },
  },
  /**
   * @memberof Endpoints/MemberAPI
   * @name member/v1/enrollment/_uuid_/schedule/therapists/_therapistId_
   * @type POST
   * @summary Submit member's selected therapist for this program enrolment
   */
  [privateTypes.SUBMIT_THERAPIST]: {
    api,
    $$method: 'post',
    $$makeUrl(configs, { uuid, therapistId }) {
      return `${getApiPath(configs)}/enrollment/${uuid}/schedule/therapists/${therapistId}`;
    },
  },
  /**
   * @memberof Endpoints/MemberAPI
   * @name member/v1/location/zip/_zipCode_?country=_country_
   * @type GET
   * @summary Get a city based on a zip code and a country combination.
   * @property zipCode {Param} Zip Code that we want to get a corresponding city for
   * @property country {QueryParam} Country we are searching for cities in
   */
  [privateTypes.GET_CITY_BY_ZIPCODE]: {
    api,
    $$method: 'get',
    $$makeUrl(configs, { zipCode, country }) {
      return `${getApiPath(configs)}/location/zip/${zipCode}?country=${country}`;
    },
  },
  /**
   * @memberof Endpoints/MemberAPI
   * @name member/v1/enrollment/_uuid_/address
   * @type GET
   * @summary Get the user's address, if any
   * @property uuid {Param} Enrollment ID
   */
  [privateTypes.GET_ADDRESS]: {
    api,
    $$method: 'get',
    $$makeUrl(configs, { uuid }) {
      return `${getApiPath(configs)}/enrollment/${uuid}/address`;
    },
  },
  /**
   * @memberof Endpoints/MemberAPI
   * @name member/v1/enrollment/_uuid_/address
   * @type POST
   * @summary Submit user input info for the address step, for a specific enrolment.
   * @property uuid {Param} Enrollment ID
   */
  [privateTypes.SUBMIT_ADDRESS]: {
    api,
    $$method: 'post',
    $$makeUrl(configs, { uuid }) {
      return `${getApiPath(configs)}/enrollment/${uuid}/address`;
    },
  },
  /**
   * @memberof Endpoints/MemberAPI
   * @name member/v1/enrollment/_enrolmentUUID_/enrolmentUUID
   * @type GET
   * @summary Retrieve all information related to enrolment
   */
  [privateTypes.GET_ENROLMENT_SUMMARY]: {
    api,
    $$method: 'get',
    $$makeUrl(configs, { enrolmentUUID }) {
      return `${getApiPath(configs)}/enrollment/${enrolmentUUID}/summary`;
    },
  },
  /**
   * @memberof Endpoints/MemberAPI
   * @name member/v1/enrollment/_enrolmentUUID_/finish
   * @type POST
   * @summary Finish enrolment program
   */
  [privateTypes.FINISH_ENROLMENT_PROGRAM]: {
    api,
    $$method: 'post',
    $$makeUrl(configs, { enrolmentUUID, keepPT }) {
      const queryParams = `?keepPT=${keepPT}`;
      return `${getApiPath(configs)}/enrollment/${enrolmentUUID}/finish/${queryParams}`;
    },
  },
  /**
   * @memberof Endpoints/MemberAPI
   * @name member/v1/enrollment/recover
   * @type GET
   * @summary Retrieve information necessary to send the user to the enrolment step they left off
   */
  [privateTypes.RECOVER_ENROLMENT]: {
    api: {
      url: api.url,
      version: '2',
    },
    $$method: 'get',
    $$makeUrl(configs) {
      return `${getApiPath(configs)}/enrollment/recover`;
    },
  },
  /**
   * @memberof Endpoints/MemberAPI
   * @name member/v1/enrollment/invite/_token_/validate
   * @type POST
   * @summary Submit user input info to check if matches the invitation.
   * @property uuid {Param} Enrollment ID
   */
  [privateTypes.VALIDATE_INVITE]: {
    api,
    $$method: 'post',
    $$makeUrl(configs, { token }) {
      return `${getApiPath(configs)}/enrollment/invite/${token}/validate`;
    },
  },
  /**
   * @memberOf Endpoints/MemberAPI
   * @name member/v1/account/status
   * @type GET
   * @summary Get account status to check availability of new enrollment
   */
  [privateTypes.CHECK_ENROLMENT_AVAILABILITY]: {
    api,
    $$method: 'get',
    $$makeUrl(configs) {
      return `${getApiPath(configs)}/account/status`;
    },
  },
  /**
   * @memberOf Endpoints/MemberAPI
   * @name member/v1/account/insurance-info
   * insurance-info
   * @type GET
   * @summary Get existing eligibility information
   */
  [privateTypes.GET_ACCOUNT_ELIGIBILITY]: {
    api,
    $$method: 'get',
    $$makeUrl(configs) {
      return `${getApiPath(configs)}/account/insurance-info`;
    },
  },
  /**
   * @memberof Endpoints/MemberAPI
   * @name member/v1/account/eligibility
   * @type POST
   * @summary Start enrolment
   */
  [privateTypes.START_ENROLMENT]: {
    api: {
      ...api,
      version: '1',
    },
    $$method: 'post',
    $$makeUrl(configs) {
      return `${getApiPath(configs)}/account/eligibility`;
    },
  },
  /**
   * @memberOf Endpoints/MemberAPI
   * @name member/v1/account/insurance-info
   * @type POST
   * @summary Update existing eligibility information
   */
  [privateTypes.UPDATE_ACCOUNT_ELIGIBILITY]: {
    api,
    $$method: 'post',
    $$makeUrl(configs) {
      return `${getApiPath(configs)}/account/insurance-info`;
    },
  },
  /**
   * @memberOf Endpoints/MemberAPI
   * @name member/v1/unit/interest
   * @type POST
   * @summary Set member interest in unit
   */
  [privateTypes.SAVE_INTEREST]: {
    api,
    $$method: 'post',
    $$makeUrl(configs) {
      return `${getApiPath(configs)}/unit/interest`;
    },
  },
  /**
   * @memberof Endpoints/MemberAPI
   * @name member/v1/enrollment/_uuid_/vaginal-anatomy
   * @type POST
   * @summary Submit the user's selected vaginal anatomy
   */
  [privateTypes.SUBMIT_VAGINAL_ANATOMY]: {
    api,
    $$method: 'post',
    $$makeUrl(configs, { uuid }) {
      return `${getApiPath(configs)}/enrollment/${uuid}/vaginal-anatomy`;
    },
  },
  /**
   * @memberof Endpoints/MemberAPI
   * @name member/v1/enrollment/_uuid_/condition/special-condition
   * @type POST
   * @summary Submit the user's selected special condition
   */
  [privateTypes.SUBMIT_SPECIAL_CONDITION]: {
    api,
    $$method: 'post',
    $$makeUrl(configs, { uuid }) {
      return `${getApiPath(configs)}/enrollment/${uuid}/condition/special-condition`;
    },
  },
  /**
   * @memberof Endpoints/MemberAPI
   * @name member/v1/enrollment/_uuid_/condition/special-condition
   * @type GET
   * @summary Get the user's special conditions
   */
  [privateTypes.GET_SPECIAL_CONDITIONS]: {
    api,
    $$method: 'get',
    $$makeUrl(configs, { uuid }) {
      return `${getApiPath(configs)}/enrollment/${uuid}/condition/special-condition`;
    },
  },
};
