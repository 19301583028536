import stackDriverLogger from 'stackdriver-logger';
import { env } from '@/config';

stackDriverLogger({
  disabled: process.env.NODE_ENV === 'development',
  reportUncaughtExceptions: true,
  logName: env.VUE_APP_STACKDRIVER_LOGGING_NAME,
  apiKey: env.VUE_APP_STACKDRIVER_LOG_API_KEY,
  logCollectorURL: env.VUE_APP_STACKDRIVER_LOG_COLLECTOR_URL,
});
