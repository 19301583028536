import { mapActions, mapGetters } from 'vuex';
import Cookies from 'js-cookie';

import { SESSION_TIMEOUT_CONFIG } from '@/scripts/constants';
import { authStoreTypes } from '@/store/types';



export default {
  data() {
    return {
      duration: Number(SESSION_TIMEOUT_CONFIG.SESSION_TIMEOUT) || SESSION_TIMEOUT_CONFIG.SESSION_DEFAULT_TIMEOUT,
      events: ['click', 'keypress', 'touchstart'],
      countdown: null,
      internalStartTimestamp: null,
    };
  },
  computed: {
    ...mapGetters({ isLoggedIn: authStoreTypes.getters.IS_LOGGED_IN }),
  },
  mounted() {
    if (!this.isLoggedIn) {
      return;
    }
    this.setupSessionTimer();
  },
  beforeDestroy() {
    this.destroySessionTimer();
  },
  watch: {
    isLoggedIn(isNowLoggedIn, wasLoggedIn) {
      if (isNowLoggedIn === wasLoggedIn) {
        return;
      }

      if (isNowLoggedIn) {
        this.setupSessionTimer();
      } else {
        this.destroySessionTimer();
      }
    },
  },
  methods: {
    ...mapActions({
      doAuthTimeout: authStoreTypes.actions.AUTH_TIMEDOUT,
    }),
    setupSessionTimer() {
      this.setupNewTimer();
      this.$nextTick(() => {
        for (let i = this.events.length - 1; i >= 0; i -= 1) {
          window.addEventListener(this.events[i], this.setupNewTimer);
        }
      });
    },
    destroySessionTimer() {
      this.resetCountdown();
      for (let i = this.events.length - 1; i >= 0; i -= 1) {
        window.removeEventListener(this.events[i], this.setupNewTimer);
      }
    },
    secondsCountdown() {
      // seconds since start
      /* eslint-disable no-bitwise */
      return this.duration - (((Date.now() - this.internalStartTimestamp) / 1000) | 0);
    },
    getStartTimestamp() {
      return Cookies.get(SESSION_TIMEOUT_CONFIG.SESSION_TIMER_COOKIE_NAME);
    },
    setStartTimestamp(value) {
      this.internalStartTimestamp = value;
      Cookies.set(SESSION_TIMEOUT_CONFIG.SESSION_TIMER_COOKIE_NAME, value);
    },
    checkIfTimerIsValid() {
      const timerCountdown = this.secondsCountdown();
      if (timerCountdown <= 0) {
        this.logout();
        this.resetCountdown();
        return;
      }

      this.scheduleTimeout(timerCountdown * 1000);
    },
    checkActivity() {
      const lastActivityTimestamp = +this.getStartTimestamp();
      if (this.internalStartTimestamp < lastActivityTimestamp) {
        this.internalStartTimestamp = lastActivityTimestamp;
        this.resetCountdown();
        this.scheduleTimeout((this.duration * 1000) - (Date.now() - this.internalStartTimestamp));
        return;
      }

      this.checkIfTimerIsValid();
    },
    scheduleTimeout(duration) {
      clearTimeout(this.countdown);
      this.countdown = setTimeout(this.checkActivity.bind(this), duration);
    },
    setupNewTimer() {
      if (this.countdown) {
        this.resetCountdown();
      }
      this.setStartTimestamp(Date.now());
      this.scheduleTimeout(this.secondsCountdown() * 1000);
    },
    resetCountdown() {
      clearTimeout(this.countdown);
    },
    logout() {
      this.$nextTick(() => this.doAuthTimeout());
      if (this.$route.name !== 'Login') {
        this.$router.push({ name: 'Login' });
      }
    },
  },
};
