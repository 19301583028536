import axios from 'axios';
import { newrelic } from '@sword-health/ui-core';
import store from '@/store';
import { authStoreTypes } from '@/store/types';
import router from '@/router';

/** Auth error codes mapping */
const AUTH_ERROR_CODES = [{ httpStatusCode: 401 }, { httpStatusCode: 400, internalCodes: [1002] }];

function checkAuthError(httpStatusCode, internalCode) {
  return AUTH_ERROR_CODES.some(authErrorCode => {
    if (authErrorCode.httpStatusCode === httpStatusCode && authErrorCode.internalCodes) {
      return authErrorCode.internalCodes.includes(internalCode);
    }
    return authErrorCode.httpStatusCode === httpStatusCode;
  });
}

/** Any status code that lie within the range of 2xx cause this function to trigger */
const handleResponse = response => response;

/** Any status codes that falls outside the range of 2xx cause this function to trigger */
const handleError = async error => {
  // Safe check for further logic
  if (!error || !error.response || !error.response.status) {
    return Promise.reject(error);
  }

  const requestInfo = error.config;
  const errorResponse = error.response;
  const errorInfo = error.response?.data ?? {};
  newrelic.noticeApiError(errorResponse.status, errorResponse);

  const isAuthError = checkAuthError(errorResponse.status, errorInfo.code);
  const refreshToken = store.getters[authStoreTypes.getters.GET_REFRESH_TOKEN];
  const isRefreshTokenRequest = requestInfo.url.includes('/refresh');

  if (isAuthError && refreshToken && !isRefreshTokenRequest) {
    try {
      // Refresh tokens
      const { accessToken } = await store.dispatch(authStoreTypes.actions.REFRESH_TOKENS);
      // Run the request again
      requestInfo.headers.Authorization = accessToken;
      return axios.request(requestInfo);
    } catch (refreshError) {
      if (window.location.pathname !== '/account/login') {
        // Redirect to login on error
        console.log('Redirecting to login in case of request error');
        router.replace({ name: 'Login' });
      }
    }
  }

  return Promise.reject(error);
};

axios.interceptors.response.use(handleResponse, handleError);
