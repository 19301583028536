
export default {
  name: 'Sword Health',
  logo: 'login/sword/logo-primary.svg',
  logoMobile: 'login/sword/logo-primary-mobile.svg',
  loginImage: 'login/sword/login-image.png',

  // zendesk color
  zendeskColor: '#1D3861',

  // translations keys
  t: {
    // Address
    onboarding2_titles_address: 'move_address_title',
    onboarding2_subtitles_address: 'move_address_subtitle',
    legal_link_irb_consent: 'legal_link_irb_consent_move',
    // Pt Selection
    /* static */
    eco_choose_pt: 'eco_choose_pt_move',
    onboarding2_titles_choose_pt_one_option: 'onboarding2_titles_choose_pt_one_option_move',
    onboarding2_titles_choose_pt: 'onboarding2_titles_choose_pt_move',
    'onboarding_choose.PT_subtitle': 'onboarding_choose.PT_subtitle_move',
    /* dynamic */
    onboarding2_stepper_choose_pt: 'onboarding2_stepper_choose_pt_move',
    onboarding3_titles_choose_pt: 'onboarding3_titles_choose_pt_move',
    onboarding3_subtitles_choose_pt: 'onboarding3_subtitles_choose_pt_move',
    onboarding3_titles_choose_pt_one_option: 'onboarding3_titles_choose_pt_one_option_move',
    eco_choose_pt_work: 'eco_choose_pt_move',
    // Demographics
    onboarding2_titles_demographics: 'onboarding2_titles_demographics_move',
    onboarding2_subtitles_demographics: 'onboarding2_subtitles_demographics_move',
  },
};
